import React, { PropsWithChildren } from "react";
import { Overlay } from "./Overlay";
import { Exercise } from "../models";

type PauseOverlayProps = {
  exercise?: Exercise;
  onResume?: () => void;
  onQuitExercise?: () => void;
};

export const PauseOverlay: React.FC<PauseOverlayProps> = (props) => {
  return (
    <Overlay style={{ backgroundColor: "rgba(51, 51, 51, 0.95)" }}>
      {/* End exercise button */}
      <div
        className="absolute bottom-10 flex h-10 w-1/4 flex-col items-center justify-center rounded-md bg-primary text-center font-semibold text-white shadow-[0px_0px_15px_0px_rgba(239,114,75,0.70)] hover:cursor-pointer hover:shadow-[0px_0px_30px_0px_rgba(239,114,75,0.70)]"
        onClick={props.onQuitExercise}
      >
        End Workout
      </div>

      {/* Play button */}
      <div
        className="absolute h-1/4 w-1/4 hover:cursor-pointer"
        onClick={props.onResume}
      >
        <svg
          className="h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 155 183"
          fill="none"
        >
          <path
            className="hover:drop-shadow-lg"
            d="M154.938 91.5005C154.943 93.8878 154.331 96.236 153.161 98.3168C151.99 100.397 150.301 102.14 148.258 103.374L21.625 180.841C19.49 182.149 17.0447 182.862 14.5417 182.909C12.0387 182.955 9.56865 182.332 7.38672 181.105C5.22558 179.897 3.42528 178.134 2.17097 176C0.916663 173.865 0.253611 171.435 0.25 168.958V14.0425C0.253611 11.5665 0.916663 9.1361 2.17097 7.0013C3.42528 4.86651 5.22558 3.10433 7.38672 1.89599C9.56865 0.668583 12.0387 0.0459026 14.5417 0.092255C17.0447 0.138607 19.49 0.852313 21.625 2.15966L148.258 79.6265C150.301 80.8611 151.99 82.6035 153.161 84.6842C154.331 86.7649 154.943 89.1131 154.938 91.5005Z"
            fill="white"
          />
        </svg>
      </div>

      {/* Exercise metadata */}
      <div className="absolute right-0 top-0 m-5 flex flex-col items-end gap-2 text-xl text-[#9a9a9a]">
        <div>Complete Metric</div>
        <div className="flex flex-row gap-1">
          <p>Target Reps:</p>
          {props.exercise?.targetCount ? (
            <p className="text-white">{props.exercise?.targetCount}</p>
          ) : (
            <p>N/A</p>
          )}
        </div>
        <div className="flex flex-row gap-1">
          <p>Time Limit:</p>{" "}
          {props.exercise?.timeLimitSec ? (
            <p className="text-white">{props.exercise?.timeLimitSec}</p>
          ) : (
            <p>N/A</p>
          )}
        </div>
      </div>

      {/* Exercise name */}
      <div className="absolute left-0 top-0 m-5 flex flex-col items-start gap-4 text-xl text-[#9a9a9a]">
        <div>Workout Name</div>
        <div className="text-4xl font-bold text-white">
          {props.exercise?.title}
        </div>
        <div className="flex flex-row gap-2">
          <p>By</p>
          <p className="text-white">HASFIT</p>
        </div>
      </div>
    </Overlay>
  );
};
