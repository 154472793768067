import { FormattedMessage, useIntl } from "react-intl";
import { Exercise } from "../models";
import { toLocalized } from "../utils/locale-util";
import { LocaleKeys } from "../models/localizable";

export function ExerciseView({
  exercise,
}: {
  exercise: Exercise;
}): JSX.Element {
  const intl = useIntl();

  function navigateToExercisePage(): void {
    // Navigate to the exercise rep count page with the exercise as a query parameter
    window.location.href = `/health-demo/exercise-rep-count?exercise=${encodeURIComponent(
      JSON.stringify(exercise),
    )}`;
  }

  return (
    <div
      className="flex h-24 max-h-32 w-full cursor-pointer flex-row justify-between overflow-auto rounded-lg border border-zinc-100 bg-stone-50 px-3 py-2 hover:bg-stone-100"
      onClick={navigateToExercisePage}
    >
      <div className="flex w-full flex-col justify-between text-start text-xl">
        <p className="text-base font-semibold">
          {/* {exercise.title} */}
          {toLocalized({
            localizable: exercise.exerciseTitle,
            locale: intl.locale as LocaleKeys,
          })}
        </p>

        <div className="text-xs font-normal italic text-subtitle">
          <p>
            <FormattedMessage id="completeMetric" />
          </p>
          <div className="flex flex-row gap-1">
            <p>
              <FormattedMessage id="targetReps" />:{" "}
              {exercise.targetCount ?? "N/A"}
            </p>
            <p className="not-italic">|</p>
            <p>
              <FormattedMessage id="timeLimit" />:{" "}
              {exercise.timeLimitSec ?? "N/A"}
            </p>
          </div>
        </div>
      </div>

      {/* Thumbnail */}
      <div className="aspect-square h-full w-auto">
        <img
          className="h-full w-full rounded-md object-cover"
          src={exercise.thumbnailUrl}
        />
      </div>
    </div>
  );
}
