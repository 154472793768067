import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import MainLayout from "../components/layout/MainLayout";
import { FIRESTORE } from "../firebase";
import { Exercise, ExerciseResult } from "../models";
import {
  ExerciseResultOverviewPanel,
  ExerciseResultTable,
} from "../components";

const HKPFA_EXERCISES_COLLECTION_NAME = "HKPFAExercises";
const HKPFA_EXERCISE_RESULTS_COLLECTION_NAME = "HKPFAExerciseResults";

export const ExerciseHistoryPage: React.FC = () => {
  // All exercise results
  const [exerciseResults, setExerciseResults] = useState<ExerciseResult[]>([]);

  // async function getExerciseResults() {
  //   // Get all exercises
  //   const exercisesSnapshot = await getDocs(
  //     collection(FIRESTORE, HKPFA_EXERCISES_COLLECTION_NAME),
  //   );

  //   // load the exercises
  //   const exercises: Exercise[] = [];
  //   exercisesSnapshot.forEach((doc) => {
  //     const docData = doc.data();
  //     const exercise = docData as Exercise;
  //     exercises.push({
  //       ...exercise,
  //       id: doc.id,
  //     });
  //   });

  //   // Get all documents
  //   const querySnapshot = await getDocs(
  //     collection(FIRESTORE, HKPFA_EXERCISE_RESULTS_COLLECTION_NAME),
  //   );

  //   // Initialize the exercise results
  //   const exerciseResults: ExerciseResult[] = [];

  //   // Iterate through each document
  //   let _ = querySnapshot.docs.forEach((doc) => {
  //     // Get the document data
  //     const docData = doc.data();

  //     // Cast to Exercise
  //     const exerciseResult = docData as ExerciseResult;

  //     // Get the date timestamp
  //     const timestamp = docData.date as Timestamp | undefined;

  //     // set the exercise title
  //     const exercise = exercises.find(
  //       (exercise) => exercise.id === exerciseResult.exerciseId,
  //     );
  //     exerciseResult.exerciseTitle = exercise?.exerciseTitle;

  //     exerciseResult.date = timestamp ? timestamp.toDate() : new Date();

  //     // Add the exercise result
  //     exerciseResults.push(exerciseResult);
  //   });

  //   // Set the exercise results
  //   setExerciseResults(exerciseResults);
  // }

  async function getExerciseResultsWithinDateRange(
    startDate: Date,
    endDate: Date,
  ) {
    // Get all documents
    const querySnapshot = await getDocs(
      query(
        collection(FIRESTORE, HKPFA_EXERCISE_RESULTS_COLLECTION_NAME),
        where("date", ">=", startDate),
        where("date", "<", endDate),
      ),
    );

    // Initialize the exercise results
    const exerciseResults: ExerciseResult[] = [];

    // Iterate through each document
    let _ = querySnapshot.docs.forEach((doc) => {
      // Get the document data
      const docData = doc.data();

      // Cast to Exercise
      const exerciseResult = docData as ExerciseResult;

      // Get the date timestamp
      const timestamp = docData.date as Timestamp | undefined;

      exerciseResult.date = timestamp ? timestamp.toDate() : new Date();

      // Add the exercise result
      exerciseResults.push(exerciseResult);
    });

    // Set the exercise results
    setExerciseResults(exerciseResults);
  }

  useEffect(() => {
    // Today's date
    const today = new Date();

    // Reset today's time to 00:00:00
    today.setHours(0, 0, 0, 0);

    // Tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    getExerciseResultsWithinDateRange(today, tomorrow);
  }, []);

  return (
    <MainLayout includeLogout={false}>
      {/* Title, subtitle and description */}
      <div className="flex-initial flex-col gap-3">
        {/* Title and subtitle */}
        <div className="flex flex-col gap-1">
          <p className="text-xl font-bold text-primary">
            <FormattedMessage id="gofaHealthTitle1" />
          </p>
          <p className="text-base font-normal italic text-subtitle">
            <FormattedMessage id="workoutHistory" />
          </p>
        </div>
      </div>

      <div className="flex grid grid-cols-1 flex-row justify-between gap-5 lg:grid-cols-3">
        {/* Exercise history overview panel */}
        <ExerciseResultOverviewPanel
          className="flex flex-col items-start justify-start gap-4 rounded bg-neutral-50 p-2"
          exerciseResults={exerciseResults}
          onDateRangeChange={getExerciseResultsWithinDateRange}
        />

        {/* Exercise history data table */}
        <ExerciseResultTable
          className="flex h-auto w-full flex-col justify-start gap-4 overflow-y-auto sm:col-span-2"
          exerciseResults={exerciseResults}
        />
      </div>
    </MainLayout>
  );
};
