import React from "react";
import { ExerciseListView } from "../components";
import MainLayout from "../components/layout/MainLayout";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const ExerciseSelectionPage: React.FC = () => {
  const navigate = useNavigate();

  const navigateToExerciseHistoryPage = () => {
    // Navigate to the exercise history page
    navigate("/health-demo/exercise-history");
  };

  return (
    <MainLayout includeLogout={false}>
      {/* Title, subtitle and description */}
      <div className="flex-initial flex-col gap-3">
        {/* Title and subtitle */}
        <div className="flex flex-col gap-1">
          <p className="text-xl font-bold text-primary">
            <FormattedMessage id="gofaHealthTitle1" />
          </p>
          <p className="text-base font-normal italic text-subtitle">
            <FormattedMessage id="gofaHealthTitle2" />
          </p>
        </div>

        {/* Description */}
        <p className="text-normal mt-2 text-xs text-neutral-800">
          <FormattedMessage id="gofaHealthHint1" />
        </p>
      </div>

      {/* List of exercises */}
      {/* scroll view for the list of exercises */}
      <ExerciseListView />

      {/* Footer */}
      <div className="mt-2 w-full flex-initial flex-col gap-2">
        {/* Divider */}
        <hr className="w-full" />

        {/* Workout history */}
        <div
          className="mt-3 flex w-full flex-row items-center justify-center gap-1 hover:cursor-pointer"
          onClick={navigateToExerciseHistoryPage}
        >
          {/* History icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.08337 2.15332C6.69099 2.15332 5.35563 2.70644 4.37106 3.69101C3.3865 4.67558 2.83337 6.01093 2.83337 7.40332H1.08337L3.35254 9.67249L3.39337 9.75415L5.75004 7.40332H4.00004C4.00004 5.14582 5.82587 3.31999 8.08337 3.31999C10.3409 3.31999 12.1667 5.14582 12.1667 7.40332C12.1667 9.66082 10.3409 11.4867 8.08337 11.4867C6.95754 11.4867 5.93671 11.0258 5.20171 10.285L4.37337 11.1133C4.85953 11.6022 5.43766 11.9899 6.07441 12.2542C6.71116 12.5185 7.39394 12.6542 8.08337 12.6533C9.47576 12.6533 10.8111 12.1002 11.7957 11.1156C12.7802 10.1311 13.3334 8.79571 13.3334 7.40332C13.3334 6.01093 12.7802 4.67558 11.7957 3.69101C10.8111 2.70644 9.47576 2.15332 8.08337 2.15332ZM7.50004 5.06999V7.98665L9.99671 9.46832L10.4167 8.76249L8.37504 7.54915V5.06999H7.50004Z"
              fill="#737082"
            />
          </svg>

          {/* History text */}
          <p className="text-sm font-normal text-subtitle">
            <FormattedMessage id="workoutHistory" />
          </p>
        </div>
      </div>
    </MainLayout>
  );
};
