import { Pose, PoseLandmark, PoseLandmarkMap } from "../models";

export class PoseLandmarkMapEMASmoother {
  private oldPoseLandmarkMap?: PoseLandmarkMap;

  /**
   * The larger the smoothing factor, the smoother the pose landmark map.
   *
   */
  smoothingFactor: number;

  /**
   * Initializes a new instance of the class with the specified smoothing factor.
   *
   * @param {number} smoothingFactor - The smoothing factor to be used for smoothing the data.
   */
  constructor(smoothingFactor: number) {
    this.smoothingFactor = smoothingFactor;
  }

  /**
   * A function that smooths the given pose landmark map using exponential moving average.
   *
   * @param {PoseLandmarkMap} poseLandmarkMap - The map of pose landmarks to be smoothed.
   * @return {PoseLandmarkMap} The smoothed pose landmark map.
   */
  smooth(poseLandmarkMap: PoseLandmarkMap): PoseLandmarkMap {
    // The first time the smoother is called
    if (!this.oldPoseLandmarkMap) {
      // The smoothed pose landmark map is the same as the pose landmark map
      // since the smoother is initially empty
      const smoothedPoseLandmarkMap = new Map(poseLandmarkMap);

      // Update old pose landmark map
      this.oldPoseLandmarkMap = new Map(smoothedPoseLandmarkMap);

      return smoothedPoseLandmarkMap;
    }

    // Calculate the smoothed pose landmark map
    const smoothedPoseLandmarkMap: PoseLandmarkMap = new Map();

    // Exponential moving average
    poseLandmarkMap.forEach((poseLandmark, poseLandmarkType) => {
      // Get the old pose landmark
      const oldPoseLandmark = this.oldPoseLandmarkMap!.get(poseLandmarkType)!;

      // Set the smoothed pose landmark
      smoothedPoseLandmarkMap.set(
        poseLandmarkType,
        new PoseLandmark(
          poseLandmark.x * (1 - this.smoothingFactor) +
            oldPoseLandmark.x * this.smoothingFactor,

          poseLandmark.y * (1 - this.smoothingFactor) +
            oldPoseLandmark.y * this.smoothingFactor,

          poseLandmark.z * (1 - this.smoothingFactor) +
            oldPoseLandmark.z * this.smoothingFactor,
        ),
      );
    });

    // Update old pose landmark map
    this.oldPoseLandmarkMap = new Map(smoothedPoseLandmarkMap);

    return smoothedPoseLandmarkMap;
  }
}

export class PoseEMASmoother {
  // The pose frame landmark map smoother
  #poseFrameLandmarkMapEMASmoother: PoseLandmarkMapEMASmoother;

  // The pose world landmark map smoother
  #poseWorldLandmarkMapEMASmoother: PoseLandmarkMapEMASmoother;

  /**
   * Creates a pose smoother that smooths both the frame and world landmark maps.
   *
   * @param smoothingFactor - The smoothing factor to be used for smoothing the data.
   */
  constructor(smoothingFactor: number) {
    // Create pose landmark map smoothers
    this.#poseFrameLandmarkMapEMASmoother = new PoseLandmarkMapEMASmoother(
      smoothingFactor,
    );
    this.#poseWorldLandmarkMapEMASmoother = new PoseLandmarkMapEMASmoother(
      smoothingFactor,
    );
  }

  smooth(pose: Pose): Pose {
    // Smooth the pose frame landmark map
    const smoothedPoseFrameLandmarkMap =
      this.#poseFrameLandmarkMapEMASmoother.smooth(pose.frameLandmarkMap);

    // Smooth the pose world landmark map
    const smoothedPoseWorldLandmarkMap =
      this.#poseWorldLandmarkMapEMASmoother.smooth(pose.worldLandmarkMap);

    // The smoothed pose
    return new Pose({
      frameLandmarkMap: smoothedPoseFrameLandmarkMap,
      worldLandmarkMap: smoothedPoseWorldLandmarkMap,
    });
  }
}
