import * as math from "mathjs";
import { PoseLandmarkMap, PoseLandmarkType } from "../models";
import { poseLandmarkToVector, vectorToPoseLandmark } from "./utils";

export function alignYawToTarget(
  curPoseWorldLandmarkMap: PoseLandmarkMap,
  tgtPoseWorldLandmarkMap: PoseLandmarkMap,
): PoseLandmarkMap {
  // Get the right vectors of the current and target poses
  const curRightVector = rightVector(curPoseWorldLandmarkMap);
  const tgtRightVector = rightVector(tgtPoseWorldLandmarkMap);

  // Cosine value of the angle between the current and target right vectors
  const cosAngle = math.dot(curRightVector, tgtRightVector) as number;

  // A vertical vector perpendicular to the plane spanned by the current and target right vectors
  const verticalVector = math.cross(curRightVector, tgtRightVector);

  // Determine whether to rotate the current pose to align with the target pose counterclockwise or clockwise
  const upVector = math.matrix([0, 1, 0]);
  const isCounterClockwise = math.dot(verticalVector, upVector) >= 0;

  // Sine value of the angle in the rotation matrix
  let sinAngle = math.norm(verticalVector) as number;
  sinAngle = isCounterClockwise ? sinAngle : -sinAngle;

  // The rotation matrix to apply to the current pose
  const rotationMatrix = math.matrix([
    [cosAngle, 0, sinAngle],
    [0, 1, 0],
    [-sinAngle, 0, cosAngle],
  ]);

  // Apply the rotation matrix to the current pose
  const alignedCurPoseWorldLandmarkMap: PoseLandmarkMap = new Map();
  curPoseWorldLandmarkMap.forEach((poseLandmark, poseLandmarkType) => {
    const vector = poseLandmarkToVector(poseLandmark);
    const transformedVector = math.multiply(rotationMatrix, vector);
    const alignedPoseLandmark = vectorToPoseLandmark(transformedVector);
    alignedCurPoseWorldLandmarkMap.set(poseLandmarkType, alignedPoseLandmark);
  });

  return alignedCurPoseWorldLandmarkMap;
}

function rightVector(poseLandmarkMap: PoseLandmarkMap): math.Matrix {
  // Get the right hip vector
  const rightHipVector = poseLandmarkToVector(
    poseLandmarkMap.get(PoseLandmarkType.RightHip)!,
  );

  // Norm of the vector
  const norm = math.norm(rightHipVector) as number;

  // Normalized vector
  const rightVector = math.multiply(1 / norm, rightHipVector);

  return rightVector;
}
