import { useEffect, useRef, useState } from "react";

/**
 * Renders a panel that displays a score within a specified range.
 *
 * @param {number} score - The score to display.
 * @param {string?} title - The title of the panel.
 * @param {number} [minScore=0] - The minimum score to display. Defaults to 0.
 * @param {number} [maxScore=100] - The maximum score to display. Defaults to 100.
 * @param {number} [numDecimals=0] - The number of decimals to display. Defaults to 0.
 * @param {string?} subceedMinScoreDisplayedContent - The content to display if the score is less than the minimum score.
 * @param {string?} exceedMaxScoreDisplayedContent - The content to display if the score is greater than the maximum score.
 * @returns {JSX.Element} The rendered ScorePanel component.
 */
export function ScorePanel({
  score,
  title,
  minScore = 0,
  maxScore = 100,
  numDecimals = 0,
  subceedMinScoreDisplayedContent,
  exceedMaxScoreDisplayedContent,
  style,
}: {
  score: number;
  title?: String;
  minScore?: number;
  maxScore?: number;
  numDecimals?: number;
  subceedMinScoreDisplayedContent?: string;
  exceedMaxScoreDisplayedContent?: string;
  style?: React.CSSProperties;
}): JSX.Element {
  // Reference to the panel div element
  // The content of the score to display in the panel
  let displayedContent: string | undefined;

  // Get the content to display
  if (score < minScore) {
    // Display the specified content if the score is less than the minimum score
    if (subceedMinScoreDisplayedContent) {
      displayedContent = subceedMinScoreDisplayedContent;
    } else {
      // Display the minimum score
      displayedContent = minScore.toFixed(numDecimals);
    }
  } else if (score > maxScore) {
    // Display the specified content if the score is greater than the maximum score
    if (exceedMaxScoreDisplayedContent) {
      displayedContent = exceedMaxScoreDisplayedContent;
    } else {
      // Display the maximum score
      displayedContent = maxScore.toFixed(numDecimals);
    }
  } else {
    // Display the score as it is
    displayedContent = score.toFixed(numDecimals);
  }

  return (
    <div
      className="absolute m-1 flex h-1/6 w-2/6 flex-col justify-between rounded bg-panel p-1 text-center text-white"
      style={style}
    >
      {/* Render the title if it exists */}
      {title ? <p className="truncate text-[1.2rem]">{title}</p> : <></>}
      <p className="truncate text-[2.5rem]">{displayedContent}</p>
    </div>
  );
}
