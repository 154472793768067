import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Overlay } from "./Overlay";

const DEFAULT_COUNTDOWN_SECONDS = 3;

type CountdownOverlayProps = {
  countdownSeconds?: number;
  onFinishCountdown?: () => void;
};

const CountdownOverlay: React.FC<CountdownOverlayProps> = (props) => {
  // Count
  const [count, setCount] = useState(
    props.countdownSeconds ?? DEFAULT_COUNTDOWN_SECONDS,
  );

  // For localization
  const intl = useIntl();

  // Formats the countdown number, 0 will be shown as "Start"
  function formatCount(count: number): string {
    if (count > 0) {
      return count.toString();
    }

    return intl.formatMessage({ id: "start" });
  }

  // Start the countdown
  useEffect(() => {
    // The countdown has finished
    if (count === 0) {
      // Finish
      const timerId = setTimeout(() => {
        props.onFinishCountdown?.();
      }, 1000);

      // Clean up
      return () => clearTimeout(timerId);
    }

    // Count down
    const timerId = setTimeout(() => {
      setCount(count - 1);
    }, 1000);

    // Clean up
    return () => clearTimeout(timerId);
  }, [count]);

  return (
    <Overlay style={{ backgroundColor: "rgba(51, 51, 51, 0.95)" }}>
      {/* Countdown Number */}
      <div className="absolute flex h-1/4 w-auto items-center justify-center hover:cursor-pointer">
        <p className="text-9xl text-white text-shadow-xl">
          {formatCount(count)}
        </p>
      </div>
    </Overlay>
  );
};

export default CountdownOverlay;
