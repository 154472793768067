import React from "react";
import { Overlay } from "./Overlay";

type CompletionOverlayProps = {
  onComplete?: () => void;
  style?: React.CSSProperties;
};

export const CompletionOverlay: React.FC<CompletionOverlayProps> = (props) => {
  // Call `onComplete` when the overlay appears
  props.onComplete?.();

  return (
    <Overlay>
      <div className="flex flex-col" style={props.style}>
        <div className="text-center text-[64px] font-extrabold italic text-orange-600 text-shadow-xl">
          Finish
        </div>
        <div className="text-center text-9xl font-extrabold italic text-white text-shadow-xl">
          Good Job!
        </div>
      </div>
    </Overlay>
  );
};
