import { PoseLandmark, PoseLandmarkMap, PoseLandmarkType } from "../models";

export abstract class PoseFrameLandmarkMapPainter {
  protected canvasContext?: CanvasRenderingContext2D;
  protected poseLandmarkTypePairs?: [PoseLandmarkType, PoseLandmarkType][];

  constructor(
    canvasContext?: CanvasRenderingContext2D,
    poseLandmarkTypePairs?: [PoseLandmarkType, PoseLandmarkType][],
  ) {
    this.canvasContext = canvasContext;
    this.poseLandmarkTypePairs = poseLandmarkTypePairs;
  }

  paint(
    poseLandmarkMap: PoseLandmarkMap,
    {
      canvasContext,
      poseLandmarkTypePairs,
      frameAspectRatio,
    }: {
      canvasContext?: CanvasRenderingContext2D;
      poseLandmarkTypePairs?: [PoseLandmarkType, PoseLandmarkType][];
      frameAspectRatio?: number;
    } = {},
  ) {
    // Get the canvas context from the attribute if not specified
    canvasContext = canvasContext ? canvasContext : this.canvasContext;

    if (!canvasContext) {
      throw new Error("Canvas context is not set");
    }

    if (!poseLandmarkTypePairs) {
      poseLandmarkTypePairs = this.poseLandmarkTypePairs;
    }

    if (!poseLandmarkTypePairs) {
      throw new Error("Pose landmark type pairs are not set");
    }

    // Clear the canvas
    canvasContext.clearRect(
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height,
    );

    // A set of pose landmark types to draw
    const poseLandmarkTypes = new Set<PoseLandmarkType>();

    // Paint line segments
    for (const [
      fromPoseLandmarkType,
      toPoseLandmarkType,
    ] of poseLandmarkTypePairs) {
      // Paint the line segment
      this.paintLineSegment(
        canvasContext,
        poseLandmarkMap,
        fromPoseLandmarkType,
        toPoseLandmarkType,
        frameAspectRatio,
      );

      // Meanwhile, collect the pose landmark types
      poseLandmarkTypes.add(fromPoseLandmarkType);
      poseLandmarkTypes.add(toPoseLandmarkType);
    }

    // Paint the pose frame landmarks
    for (const poseLandmarkType of poseLandmarkTypes) {
      this.paintPoseLandmark(
        canvasContext,
        poseLandmarkMap,
        poseLandmarkType,
        frameAspectRatio,
      );
    }

    // For nose, paint a big circle to mask the face
    // Draw the central dot
    // canvasContext.beginPath();
    // canvasContext.arc(
    //   poseLandmarkMap.get(PoseLandmarkType.Nose)!.x * canvasContext.canvas.width,
    //   poseLandmarkMap.get(PoseLandmarkType.Nose)!.y * canvasContext.canvas.height,
    //   120,
    //   0,
    //   2 * Math.PI,
    // );
    // canvasContext.fillStyle = "white";
    // canvasContext.fill();
  }

  /**
   * Paints a marker of a single pose landmark.
   * This is typically a circle, but can also be a square or any other shape.
   *
   * @param {PoseLandmarkType} poseLandmarkType - The type of the pose landmark to be drawn.
   * @param {number} frameAspectRatio - Aspect ratio of the frame.
   */
  abstract paintPoseLandmark(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    poseLandmarkType: PoseLandmarkType,
    frameAspectRatio?: number,
  ): void;

  /**
   * Paints a line segment between two specified pose landmarks.
   *
   * @param {PoseLandmarkType} fromPoseLandmarkType - The type of the starting pose landmark.
   * @param {PoseLandmarkType} toPoseLandmarkType - The type of the ending pose landmark.
   */
  abstract paintLineSegment(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    fromPoseLandmarkType: PoseLandmarkType,
    toPoseLandmarkType: PoseLandmarkType,
    frameAspectRatio?: number,
  ): void;

  /**
   * Finds the pose landmark coordinate on the canvas.
   *
   * @param canvasContext - Canvas rendering context.
   * @param poseLandmark - Pose landmark to be painted.
   * @param frameAspectRatio - Aspect ratio of the frame.
   * @returns { x: number; y: number } - The pose landmark coordinate on the canvas.
   */
  protected findPoseLandmarkCoordinateOnCanvas(
    canvasContext: CanvasRenderingContext2D,
    poseLandmark: PoseLandmark,
    frameAspectRatio?: number,
  ): { x: number; y: number } {
    // If the frame aspect ratio is not specified,
    // the coordinate is calculated based on the canvas aspect ratio
    if (!frameAspectRatio) {
      return {
        x: poseLandmark.x * canvasContext.canvas.width,
        y: poseLandmark.y * canvasContext.canvas.height,
      };
    }

    // Get the aspect ratio of the canvas
    const canvasAspectRatio =
      canvasContext.canvas.width / canvasContext.canvas.height;

    // The left and right sides of the frame are cropped
    if (frameAspectRatio > canvasAspectRatio) {
      const frameWidth = canvasContext.canvas.height * frameAspectRatio;
      const frameHeight = canvasContext.canvas.height;
      const offsetX = -(frameWidth - canvasContext.canvas.width) / 2;

      return {
        x: poseLandmark.x * frameWidth + offsetX,
        y: poseLandmark.y * frameHeight,
      };
    }

    // The top and bottom sides of the frame are cropped
    else {
      const frameWidth = canvasContext.canvas.width;
      const frameHeight = canvasContext.canvas.width / frameAspectRatio;
      const offsetY = -(frameHeight - canvasContext.canvas.height) / 2;

      return {
        x: poseLandmark.x * frameWidth,
        y: poseLandmark.y * frameHeight + offsetY,
      };
    }
  }
}
