import * as math from "mathjs";
import { PoseLandmark } from "../models";

export function poseLandmarkToVector(poseLandmark: PoseLandmark): math.Matrix {
  return math.matrix([poseLandmark.x, poseLandmark.y, poseLandmark.z]);
}

export function vectorToPoseLandmark(vector: math.Matrix): PoseLandmark {
  return new PoseLandmark(
    vector.get([0]) as number,
    vector.get([1]) as number,
    vector.get([2]) as number,
  );
}
