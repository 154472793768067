import { PoseLandmarkMap, PoseLandmarkType } from "../models";
import { PoseFrameLandmarkMapPainter } from "./pose-frame-andmark-map-painter";

export class SitWellPoseFrameLandmarkMapPainter extends PoseFrameLandmarkMapPainter {
  constructor(canvasContext?: CanvasRenderingContext2D) {
    super(canvasContext, DEFAULT_POSE_LANDMARK_TYPE_PAIRS);
  }

  paintPoseLandmark(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    poseLandmarkType: PoseLandmarkType,
    frameAspectRatio?: number,
  ): void {
    // Settings
    const radius = 10;
    const gofaThemeColor = "#FF3702";

    // Get the pose landmark
    const poseLandmark = poseLandmarkMap!.get(poseLandmarkType)!;

    // Transform the coordinate
    const { x: poseLandmarkX, y: poseLandmarkY } =
      this.findPoseLandmarkCoordinateOnCanvas(
        canvasContext,
        poseLandmark,
        frameAspectRatio,
      );

    // Draw the border
    canvasContext.beginPath();
    canvasContext.arc(poseLandmarkX, poseLandmarkY, radius, 0, 2 * Math.PI);
    canvasContext.lineWidth = 3;
    canvasContext.strokeStyle = "white";
    canvasContext.stroke();

    // Draw the central dot
    canvasContext.beginPath();
    canvasContext.arc(poseLandmarkX, poseLandmarkY, radius / 2, 0, 2 * Math.PI);
    canvasContext.fillStyle = gofaThemeColor;
    canvasContext.fill();

    // Draw the coordinate text, reversed y-axis
    // canvasContext.font = "16px Arial";
    // canvasContext.fillStyle = "white";
    // canvasContext.fillText(
    //   `${poseLandmarkType}: (${poseLandmarkX.toFixed(0)}, ${poseLandmarkY.toFixed(0)})`,
    //   poseLandmarkX,
    //   poseLandmarkY,
    // );

  }

  paintLineSegment(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    fromPoseLandmarkType: PoseLandmarkType,
    toPoseLandmarkType: PoseLandmarkType,
    frameAspectRatio?: number,
  ): void {
    // Settings
    const lineWidth = 3;
    const lineColor = "rgba(255, 255, 255, 0.9)";

    // Get the pose landmarks
    const fromPoseLandmark = poseLandmarkMap.get(fromPoseLandmarkType)!;
    const toPoseLandmark = poseLandmarkMap.get(toPoseLandmarkType)!;

    // Transform the coordinate
    const { x: fromPoseLandmarkX, y: fromPoseLandmarkY } =
      this.findPoseLandmarkCoordinateOnCanvas(
        canvasContext,
        fromPoseLandmark,
        frameAspectRatio,
      );

    const { x: toPoseLandmarkX, y: toPoseLandmarkY } =
      this.findPoseLandmarkCoordinateOnCanvas(
        canvasContext,
        toPoseLandmark,
        frameAspectRatio,
      );

    // Paint the line segment
    canvasContext.beginPath();
    canvasContext.moveTo(fromPoseLandmarkX, fromPoseLandmarkY);
    canvasContext.lineTo(toPoseLandmarkX, toPoseLandmarkY);
    canvasContext.lineWidth = lineWidth;
    canvasContext.strokeStyle = lineColor;
    canvasContext.stroke();
  }
}

export const DEFAULT_POSE_LANDMARK_TYPE_PAIRS: [
  PoseLandmarkType,
  PoseLandmarkType,
][] = [

  // Left arm
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.LeftElbow],
  [PoseLandmarkType.LeftElbow, PoseLandmarkType.LeftWrist],

  // Right arm
  [PoseLandmarkType.RightShoulder, PoseLandmarkType.RightElbow],
  [PoseLandmarkType.RightElbow, PoseLandmarkType.RightWrist],

  // Torso
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.RightShoulder],
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.LeftHip],
  [PoseLandmarkType.RightShoulder, PoseLandmarkType.RightHip],
  [PoseLandmarkType.LeftHip, PoseLandmarkType.RightHip],

  // Left leg
  [PoseLandmarkType.LeftHip, PoseLandmarkType.LeftKnee],
  [PoseLandmarkType.LeftKnee, PoseLandmarkType.LeftAnkle],

  // Right leg
  [PoseLandmarkType.RightHip, PoseLandmarkType.RightKnee],
  [PoseLandmarkType.RightKnee, PoseLandmarkType.RightAnkle],
];
