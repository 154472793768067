import { useEffect, useRef, useState } from "react";

import {
  WebcamView,
  PoseCanvas,
  VideoPlayer,
  RepCountPanel,
  ProgressBar,
  ScorePanel,
} from "../components";

import {
  PoseLandmarkMap,
  PoseLandmark,
  useVideoPoseDetector,
  DefaultPoseFrameLandmarkMapPainter,
  usePoseFrameLandmarkMapPainterRef,
  PoseEMASmoother,
  PoseSimilarityEvaluator,
  KeyframeBasedRepCounter,
  stringToPoseLandmarkType,
} from "../packages/gofa-mediapipe-pose-ts";

import { FIRESTORE } from "../firebase";

// Import the functions you need from the SDKs you need
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";

import { plainToInstance } from "class-transformer";

export function RepCountPage(): JSX.Element {
  const webcamVideoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [videoUrl, setVideoUrl] = useState<string>();

  const videoPoseDetector = useVideoPoseDetector(webcamVideoRef, {
    onPoseDetected: (pose) => {
      // Smooth the pose
      const smoothedPose = poseSmoother.smooth(pose);

      // Get the rep counter
      const repCounter = repCounterRef.current;
      if (!repCounter) return;

      // Observe user's current pose and update the rep count
      repCounter.observe(smoothedPose.worldLandmarkMap);

      // Update rep count
      setRepCount(repCounter.count);

      // Update the keyframe index
      setKeyframeIndex(repCounter.currentTgtKeyframeIndex);

      // Update pose similarity
      setPoseSimilarity(repCounter.poseSimilarity ?? 0);

      // Get the aspect ratio of the video frame
      const frameAspectRatio =
        webcamVideoRef.current!.videoWidth /
        webcamVideoRef.current!.videoHeight;

      // Get the painter
      const poseFrameLandmarkMapPainter =
        poseFrameLandmarkMapPainterRef.current;
      if (!poseFrameLandmarkMapPainter) return;

      // Paint on the canvas
      poseFrameLandmarkMapPainter.paint(smoothedPose.frameLandmarkMap, {
        frameAspectRatio: frameAspectRatio,
      });
    },
  });

  // Pose smoother
  const smoothingFactor = 0.7;
  const poseSmoother = new PoseEMASmoother(smoothingFactor);

  // Pose frame landmark map painter
  const poseFrameLandmarkMapPainterRef = usePoseFrameLandmarkMapPainterRef(
    canvasRef,
    DefaultPoseFrameLandmarkMapPainter,
  );

  // Pose similarity evaluator
  const poseSimilarityEvaluator = new PoseSimilarityEvaluator({
    mismatchThreshold: 0.8,
    mismatchPenaltyPerBodyPart: 0.1,
    allowMirroring: true,
  });

  // Rep counter
  const [repCounter, setRepCounter] = useState<KeyframeBasedRepCounter>();
  const repCounterRef = useRef<KeyframeBasedRepCounter>();

  // Rep count
  const [repCount, setRepCount] = useState(0);

  // Keyframe index
  const [keyframeIndex, setKeyframeIndex] = useState(0);

  // Pose similarity
  const [poseSimilarity, setPoseSimilarity] = useState(0);

  // Set reference to rep counter
  useEffect(() => {
    if (!repCounter) return;
    repCounterRef.current = repCounter;
  }, [repCounter]);

  // Initialize and start the video pose detector
  useEffect(() => {
    videoPoseDetector?.initialize().then(() => {
      videoPoseDetector?.start();
    });
  }, [videoPoseDetector]);

  useEffect(() => {
    getDocs(
      query(
        collection(FIRESTORE, "RepCountExercises"),
        where("lessonName", "==", "Strength #10"),
        // where("exerciseName", "==", "Lateral Travelling Squat")
        where("exerciseName", "==", "Dumbbell Deadlifts"),
      ),
    ).then((querySnapshot) => {
      // Get document data
      const docData = querySnapshot.docs[0].data();

      // Get the lesson name,
      const lessonName = docData.lessonName as string;

      // Get keyframe timestamps
      const keyframeTimestamps = docData.keyframeTimestamps as number[];

      getDocs(
        query(
          collection(FIRESTORE, "Lessons"),
          where("uniqueID", "==", lessonName),
        ),
      ).then((querySnapshot) => {
        // Get document data
        const docData = querySnapshot.docs[0].data();

        // log the data
        console.log("docData", docData);

        // Get the video url
        const videoUrls = docData.videoURLs as string[];
        const videoUrl = videoUrls[0];

        // log the video url
        console.log("videoUrl", videoUrl);

        setVideoUrl(videoUrl);
      });

      // * Both lesson name and keyframe timestamps are required
      // * to find associated pose landmark maps

      getDocs(
        query(
          collection(FIRESTORE, "LessonTimedPoseLandmarkMaps"),
          where("lessonName", "==", lessonName),
          where("timestampMs", "in", keyframeTimestamps),
        ),
      ).then((querySnapshot) => {
        // Initialize target keyframe pose landmark maps
        const tgtKeyframePoseLandmarkMaps: PoseLandmarkMap[] = [];

        // Get the data documents and sort by timestamp
        const docs = querySnapshot.docs.map((doc) => doc.data());
        docs.sort((a, b) => a.timestampMs - b.timestampMs);

        docs.forEach((docData) => {
          // Create a new pose landmark map
          const poseLandmarkMap: PoseLandmarkMap = new Map();

          const rawMap = plainToInstance(Map, docData.poseLandmarkMap);
          for (const [rawPoseLandmarkType, rawPoseLandmark] of rawMap) {
            const poseLandmarkType = stringToPoseLandmarkType(
              rawPoseLandmarkType as string,
            );
            const poseLandmark = plainToInstance(PoseLandmark, rawPoseLandmark);

            poseLandmarkMap.set(poseLandmarkType, poseLandmark);
          }

          // Add pose landmark map
          tgtKeyframePoseLandmarkMaps.push(poseLandmarkMap);
        });

        console.log("tgtKeyframePoseLandmarkMaps", tgtKeyframePoseLandmarkMaps);

        // Set the rep counter
        setRepCounter(
          new KeyframeBasedRepCounter({
            poseSimilarityEvaluator: poseSimilarityEvaluator,
            poseSimilarityThreshold: 0.8,
            tgtKeyframePoseLandmarkMaps: tgtKeyframePoseLandmarkMaps,
          }),
        );
      });
    });

    return () => {
      console.log("exit");
    };
  }, []);

  return (
    <div className="flex h-screen w-full flex-row">
      <div className="relative flex h-full w-1/2">
        <VideoPlayer url={videoUrl ?? ""} playing />

        {/* Dummny Video Title */}
        <div className="absolute flex w-full flex-row justify-center">
          <div className="m-1 rounded bg-panel p-2 text-white">Dummy Video</div>
        </div>
      </div>

      <div className="relative flex h-full w-1/2">
        <WebcamView videoRef={webcamVideoRef} />
        <PoseCanvas canvasRef={canvasRef} />
        <RepCountPanel
          count={repCount}
          style={{ right: 5, width: "16%", height: "16%" }}
        />
        <ProgressBar
          value={poseSimilarity}
          maxValue={1}
          style={{ right: 5, width: "25px", height: "80%" }}
        />

        <ScorePanel
          score={keyframeIndex}
          title="Keyframe Index"
          style={{ left: 5, width: "16%", height: "16%" }}
        />

        {/* Dummny Title */}
        <div className="absolute flex w-full flex-row justify-center">
          <div className="m-1 rounded bg-panel p-2 text-white">
            Dumbbel Deadlifts
          </div>
        </div>

        <div className="absolute bottom-0 left-0 m-1 flex w-1/3 flex-row justify-between gap-1">
          <button
            className="flex-grow rounded bg-panel p-1 text-white"
            onClick={() => videoPoseDetector?.start()}
          >
            Start
          </button>
          <button
            className="flex-grow rounded bg-panel p-1 text-white"
            onClick={() => videoPoseDetector?.stop()}
          >
            Stop
          </button>
        </div>
      </div>
    </div>
  );
}
