// src/components/Layout.tsx
import React from "react";
import Footer from "./MainFooter";
import TopRightButtons from "./TopRightButtons";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  includeLogout?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, includeLogout }) => {
  return (
    <div className="flex h-full min-h-screen flex-col items-center justify-center bg-gray-100 px-3">
      {/* position top right corner a LanguageSwitcher */}
      <div className="absolute right-0 top-0 m-4 rounded-lg p-2 text-white">
        <TopRightButtons
          includeLogout={includeLogout != null ? includeLogout : true}
        />
      </div>
      <div className="flex w-full grow flex-col items-center justify-center overflow-y-auto">
        {children}
      </div>
      <Footer className="mb-2 flex h-auto pt-2 text-center text-xs text-gray-500" />
    </div>
  );
};

export default Layout;
