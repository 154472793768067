import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore/lite";
import { useStopwatch } from "../hooks";

import {
  WebcamView,
  PoseCanvas,
  VideoPlayer,
  RepCountPanel,
  ProgressBar,
  StopwatchView,
  PauseButton,
  PauseOverlay,
  CompletionOverlay,
  LoadingOverlay,
  CountdownOverlay,
} from "../components";

import {
  useVideoPoseDetector,
  DefaultPoseFrameLandmarkMapPainter,
  usePoseFrameLandmarkMapPainterRef,
  PoseEMASmoother,
  PoseSimilarityEvaluator,
  KeyframeBasedRepCounter,
  PoseLandmarkMap,
} from "../packages/gofa-mediapipe-pose-ts";

import { Exercise, ExerciseResult } from "../models";
import { FIRESTORE } from "../firebase";

// Sound files
import incrementRepCountSound from "../assets/sound/increment-rep-count.mp3";
import completeExerciseSound from "../assets/sound/complete-exercise.mp3";
import backgroundMusic from "../assets/sound/hip-hop-rock-beats.mp3";

import { Slider } from "@mui/material";

// Collection name in the Firestore database
const EXERCISE_KEYFRAME_POSE_LANDMARK_MAP_COLLECTION_NAME =
  "HKPFAExerciseKeyframePoseLandmarkMaps";

const DEFAULT_POSE_SIMILARITY_THRESHOLD = 0.7;

export const ExerciseRepCountPage: React.FC = () => {
  // Exercise used in this page
  const [exercise, setExercise] = useState<Exercise>();

  // Control whether to play the video
  // Stop the video at the beginning
  const [playVideo, setPlayVideo] = useState(false);

  // Webcam
  const webcamVideoRef = useRef<HTMLVideoElement>(null);

  // Canvas to paint user's pose
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Stopwatch
  const { stopwatch, elapsedTimeSec } = useStopwatch();

  // Sound audios
  const incrementRepCountSountAudio = new Audio(incrementRepCountSound);
  const completeExerciseSountAudio = new Audio(completeExerciseSound);
  const [backgroundMusicAudio, setBackgroundMusicAudio] = useState(
    new Audio(backgroundMusic),
  );

  const videoPoseDetector = useVideoPoseDetector(webcamVideoRef, {
    onPoseDetected: (pose) => {
      // Smooth the pose
      const smoothedPose = poseSmoother.smooth(pose);

      // Get the rep counter
      const repCounter = repCounterRef.current;
      if (!repCounter) return;

      // Observe user's current pose and update the rep count
      repCounter.observe(smoothedPose.worldLandmarkMap);

      // Update the keyframe index
      setKeyframeIndex(repCounter.currentTgtKeyframeIndex);

      // Update pose similarity
      setPoseSimilarity(repCounter.poseSimilarity ?? 0);

      // Get the aspect ratio of the video frame
      const frameAspectRatio =
        webcamVideoRef.current!.videoWidth /
        webcamVideoRef.current!.videoHeight;

      // Get the painter
      const poseFrameLandmarkMapPainter =
        poseFrameLandmarkMapPainterRef.current;
      if (!poseFrameLandmarkMapPainter) return;

      // Paint on the canvas
      poseFrameLandmarkMapPainter.paint(smoothedPose.frameLandmarkMap, {
        frameAspectRatio: frameAspectRatio,
      });
    },
  });

  // Pose smoother
  const smoothingFactor = 0.7;
  const poseSmoother = new PoseEMASmoother(smoothingFactor);

  // Pose frame landmark map painter
  const poseFrameLandmarkMapPainterRef = usePoseFrameLandmarkMapPainterRef(
    canvasRef,
    DefaultPoseFrameLandmarkMapPainter,
  );

  // Pose similarity evaluator
  const poseSimilarityEvaluator = new PoseSimilarityEvaluator({
    mismatchThreshold: DEFAULT_POSE_SIMILARITY_THRESHOLD,
    mismatchPenaltyPerBodyPart: 0.1,
    allowMirroring: true,
  });

  // Rep counter
  const [repCounter, setRepCounter] = useState<KeyframeBasedRepCounter>();
  const repCounterRef = useRef<KeyframeBasedRepCounter>();

  // Pose similarity threshold
  const [poseSimilarityThreshold, setPoseSimilarityThreshold] = useState(
    DEFAULT_POSE_SIMILARITY_THRESHOLD,
  );

  // Rep count
  const [repCount, setRepCount] = useState(0);

  // Keyframe index
  const [keyframeIndex, setKeyframeIndex] = useState(0);

  // Pose similarity
  const [poseSimilarity, setPoseSimilarity] = useState(0);

  // Whether to show the loading overlay
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);

  // Whether to show the countdown overlay
  const [showCountdownOverlay, setShowCountdownOverlay] = useState(false);

  // Whether to show the pause overlay
  const [showPauseOverlay, setShowPauseOverlay] = useState(false);

  // Whether to show the completion overlay
  const [showCompletionOverlay, setShowCompletionOverlay] = useState(false);

  /**
   * Loads the exercise from the URL.
   * It then loads the keyframe pose landmark maps and
   * pose similarity threshold for the exercise from the Firestore database.
   */
  function loadExercise() {
    // Find the exercise ID in the URL
    const query = new URLSearchParams(window.location.search);

    // Get the encoded exercise string from the URL
    const exerciseString = query.get("exercise");
    if (!exerciseString) throw new Error("Exercise not found in URL");

    // Parse to exercise
    let exercise = JSON.parse(query.get("exercise")!) as Exercise;

    // Set the exercise
    setExercise(exercise);
  }

  function onIncrementRepCount(repCount: number) {
    // Update rep count
    setRepCount(repCount);

    // Reset the sound
    incrementRepCountSountAudio.pause();
    incrementRepCountSountAudio.currentTime = 0;

    // Play sound
    incrementRepCountSountAudio.play();
  }

  async function loadRepCounter(exerciseId: string) {
    // Get data from Firestore
    const document = await getDoc(
      doc(
        FIRESTORE,
        EXERCISE_KEYFRAME_POSE_LANDMARK_MAP_COLLECTION_NAME,
        exerciseId,
      ),
    );

    // Get the document data
    const documentData = document.data();
    if (!documentData) throw new Error("Keyframe pose landmark map not found");

    // Get keyframe pose landmark maps
    const keyframePoseLandmarkMaps = (
      documentData.keyframePoseLandmarkMaps as object[]
    ).map(PoseLandmarkMap.fromJson);

    // Get pose similarity threshold
    const poseSimilarityThreshold =
      documentData.poseSimilarityThreshold ?? DEFAULT_POSE_SIMILARITY_THRESHOLD;

    // Set the rep counter
    setRepCounter(
      new KeyframeBasedRepCounter({
        poseSimilarityEvaluator: poseSimilarityEvaluator,
        poseSimilarityThreshold: poseSimilarityThreshold,
        tgtKeyframePoseLandmarkMaps: keyframePoseLandmarkMaps,
        onIncrementRepCount: onIncrementRepCount,
      }),
    );
  }

  function completeLoading() {
    // Dismiss the loading overlay
    setShowLoadingOverlay(false);

    // Show the countdown overlay
    setShowCountdownOverlay(true);
  }

  function finishiCountdown() {
    // Dismiss the countdown overlay
    setShowCountdownOverlay(false);

    // Start playing the video
    setPlayVideo(true);

    // Start the stopwatch
    stopwatch.start();

    // Start the video pose detector
    videoPoseDetector?.start();
  }

  function pauseExercise() {
    // Stop the video
    setPlayVideo(false);

    // Stop the video pose detector
    videoPoseDetector?.stop();

    // Clear the canvas
    clearCanvas();

    // Stop the stopwatch
    stopwatch?.stop();

    // Stop background music
    backgroundMusicAudio.pause();

    // Show the pause overlay
    setShowPauseOverlay(true);
  }

  function resumeExercise() {
    // Resume the video
    setPlayVideo(true);

    // Start the video pose detector
    videoPoseDetector?.start();

    // Start the stopwatch
    stopwatch?.start();

    // Start background music
    backgroundMusicAudio.play();

    // Dismiss the pause overlay
    setShowPauseOverlay(false);
  }

  function completeExercise() {
    // Stop the video pose detector
    videoPoseDetector?.stop();

    // Clear the canvas
    clearCanvas();

    // Stop the stopwatch
    stopwatch?.stop();

    // Stop background music
    backgroundMusicAudio.pause();

    // Play sound
    completeExerciseSountAudio.play();

    // Navigate to the result page after some time
    setTimeout(navigateToResultPage, 3000);
  }

  function navigateToResultPage() {
    // Clear the history of the current page
    window.history.replaceState(null, document.title, window.location.href);

    // Get the elapsed time
    const elapsedTimeSec = stopwatch?.elapsedTimeSec;
    if (!elapsedTimeSec) throw new Error("Stopwatch not started");

    // Create exercise result
    if (!exercise) throw new Error("Exercise not set");
    const exerciseResult: ExerciseResult = {
      title: exercise.title,
      exerciseId: exercise.id,
      elapsedTimeSec: elapsedTimeSec,
      targetCount: exercise.targetCount!,
    };

    // Navigate to the result page
    // Pass the exercise result to the result page as a query parameter
    window.location.href = `/health-demo/exercise-result?exerciseResult=${encodeURIComponent(
      JSON.stringify(exerciseResult),
    )}`;
  }

  function quitExercise() {
    // Stop playing the background music
    backgroundMusicAudio.pause();

    // Navigate back to the previous page
    window.history.replaceState(null, document.title, window.location.href);
    window.history.back();
  }

  function handlePopState(event: PopStateEvent) {
    if (event.state) {
      quitExercise();
    }
  }

  function clearCanvas() {
    // Get the canvas
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Get the canvas context
    const canvasContext = canvas.getContext("2d");
    if (!canvasContext) return;

    // Clear the canvas
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
  }

  function handleChangePoseSimilarityThreshold(
    event: Event,
    newPoseSimilarityThreshold: number | number[],
  ) {
    // Get the new pose similarity threshold
    newPoseSimilarityThreshold = newPoseSimilarityThreshold as number;

    // Update the pose similarity threshold
    setPoseSimilarityThreshold(newPoseSimilarityThreshold);

    // Update the rep counter
    if (!repCounter) return;
    repCounter.poseSimilarityThreshold = newPoseSimilarityThreshold;
  }

  useEffect(() => {
    // Load the exercise
    loadExercise();

    // Add a popstate listener to quit the exercise when the user navigates back
    window.addEventListener("popstate", handlePopState);

    // Play the background music

    // Play in a loop
    backgroundMusicAudio.loop = true;

    // Turn the volume down
    backgroundMusicAudio.volume = 0.3;

    // Start playing
    backgroundMusicAudio.play();

    // Clean up
    return () => {
      // Remove the popstate listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Set reference to rep counter
  useEffect(() => {
    if (!repCounter) return;
    repCounterRef.current = repCounter;
  }, [repCounter]);

  // Initialize and start the video pose detector
  useEffect(() => {
    videoPoseDetector?.initialize().then(() => {
      // Complete loading
      completeLoading();
    });
  }, [videoPoseDetector]);

  // Check if the user has reached the target rep count
  useEffect(() => {
    // Get the target rep count
    const targetCount = exercise?.targetCount;
    if (!targetCount) return;

    // Check if the user has reached the target rep count
    if (repCount >= targetCount) {
      // Stop the video
      setPlayVideo(false);

      // Show the completion overlay
      setShowCompletionOverlay(true);
    }
  }, [repCount]);

  // Set rep counter
  useEffect(() => {
    if (!exercise) return;

    const exerciseId = exercise?.id;
    if (!exerciseId) throw new Error("Exercise not set");

    loadRepCounter(exerciseId);
  }, [exercise]);

  return (
    <div className="flex h-screen w-full flex-row">
      {/* Loading Overlay */}
      {showLoadingOverlay ? <LoadingOverlay /> : null}

      {/* Countdown Overlay */}
      {showCountdownOverlay ? (
        <CountdownOverlay onFinishCountdown={finishiCountdown} />
      ) : null}

      {/* Pause Overlay */}
      {showPauseOverlay ? (
        <PauseOverlay
          exercise={exercise}
          onResume={resumeExercise}
          onQuitExercise={quitExercise}
        />
      ) : null}

      {/* Completion Overlay */}
      {showCompletionOverlay ? (
        <CompletionOverlay onComplete={completeExercise} />
      ) : null}

      {/* Left Screen for the Video */}
      <div className="relative flex h-full w-1/2">
        {/* Video player */}
        <VideoPlayer
          url={exercise?.videoUrl ?? ""}
          playing={playVideo}
          loop
          muted
        />

        {/* Pause Button */}
        <PauseButton onClick={pauseExercise} style={{ margin: 5 }} />

        {/* Pose Similarity Threshold Slider */}
        <div
          className="p-2font-medium absolute right-0 top-0 flex w-2/3 flex-row items-center gap-4 rounded-md bg-panel p-2 text-white"
          style={{ margin: 5 }}
        >
          <p>Easy</p>
          <Slider
            className="opacity-60"
            min={0.1}
            max={0.9}
            step={0.1}
            value={poseSimilarityThreshold}
            onChange={handleChangePoseSimilarityThreshold}
          />
          <p>Hard</p>
        </div>
      </div>

      {/* Right Screen for the User */}
      <div className="relative flex h-full w-1/2">
        {/* Webcam and Pose Canvas */}
        <WebcamView videoRef={webcamVideoRef} />
        <PoseCanvas canvasRef={canvasRef} />

        <RepCountPanel
          count={repCount}
          targetCount={exercise?.targetCount ?? 0}
          style={{
            left: 0,
            height: "16%",
            maxHeight: "2.5rem",
            margin: 5,
          }}
        />

        <ProgressBar
          value={poseSimilarity}
          maxValue={1}
          style={{ right: 0, width: "25px", height: "80%", margin: 5 }}
        />

        <StopwatchView
          elapsedTimeSec={elapsedTimeSec}
          style={{
            right: 0,
            height: "16%",
            maxHeight: "2.5rem",
            margin: 5,
          }}
        />

        {/* <ScorePanel
          score={keyframeIndex}
          title="Keyframe Index"
          style={{ bottom: 0, width: "16%", height: "16%", margin: 5 }}
        /> */}
      </div>
    </div>
  );
};
