import React from "react";
import useSound from "use-sound";

// Sound files
import incrementRepCountSound from "../assets/sound/increment-rep-count.mp3";

const TestSoundPage: React.FC = () => {
  // Functions for playing sounds
  const [playIncrementRepCountSound] = useSound(incrementRepCountSound);

  const playSound = () => {
    playIncrementRepCountSound();

    setInterval(() => {
      playIncrementRepCountSound();
    }, 500);
  };

  return (
    <div>
      <h1>Test Sound Page</h1>

      <button
        className="button btn btn-primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => playSound()}
      >
        Play Increment Rep Count Sound
      </button>
    </div>
  );
};

export default TestSoundPage;
