import { PoseLandmarkMap, PoseLandmarkType } from "../models";
import { PoseFrameLandmarkMapDrawer } from "./pose-frame-andmark-map-drawer";

export class DefaultPoseFrameLandmarkMapDrawer extends PoseFrameLandmarkMapDrawer {
  constructor(canvasContext?: CanvasRenderingContext2D) {
    super(canvasContext, defaultPoseLandmarkTypePairs);
  }

  drawPoseLandmark(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    poseLandmarkType: PoseLandmarkType,
    aspectRatio?: number,
  ): void {
    // Get a function that transforms pose landmark coordinate
    const transformPoseLandmarkCoordinate =
      this.transformPoseLandmarkCoordinateFunctional(
        canvasContext,
        aspectRatio,
      );

    // Settings
    const radius = 10;
    const gofaThemeColor = "#FF3702";

    // Get the pose landmark
    const poseLandmark = poseLandmarkMap!.get(poseLandmarkType)!;

    // Transform the coordinate
    const { x: poseLandmarkX, y: poseLandmarkY } =
      transformPoseLandmarkCoordinate(poseLandmark);

    // Draw the border
    canvasContext.beginPath();
    canvasContext.arc(poseLandmarkX, poseLandmarkY, radius, 0, 2 * Math.PI);
    canvasContext.lineWidth = 3;
    canvasContext.strokeStyle = "white";
    canvasContext.stroke();

    // Draw the central dot
    canvasContext.beginPath();
    canvasContext.arc(poseLandmarkX, poseLandmarkY, radius / 2, 0, 2 * Math.PI);
    canvasContext.fillStyle = gofaThemeColor;
    canvasContext.fill();
  }

  drawLineSegment(
    canvasContext: CanvasRenderingContext2D,
    poseLandmarkMap: PoseLandmarkMap,
    fromPoseLandmarkType: PoseLandmarkType,
    toPoseLandmarkType: PoseLandmarkType,
    aspectRatio?: number,
  ): void {
    // Get a function that transforms pose landmark coordinate
    const transformPoseLandmarkCoordinate =
      this.transformPoseLandmarkCoordinateFunctional(
        canvasContext,
        aspectRatio,
      );

    // Settings
    const lineWidth = 3;
    const lineColor = "rgba(255, 255, 255, 0.9)";

    // Get the pose landmarks
    const fromPoseLandmark = poseLandmarkMap.get(fromPoseLandmarkType)!;
    const toPoseLandmark = poseLandmarkMap.get(toPoseLandmarkType)!;

    // Transform the coordinate
    const { x: fromPoseLandmarkX, y: fromPoseLandmarkY } =
      transformPoseLandmarkCoordinate(fromPoseLandmark);
    const { x: toPoseLandmarkX, y: toPoseLandmarkY } =
      transformPoseLandmarkCoordinate(toPoseLandmark);

    // Draw the line segment
    canvasContext.beginPath();
    canvasContext.moveTo(fromPoseLandmarkX, fromPoseLandmarkY);
    canvasContext.lineTo(toPoseLandmarkX, toPoseLandmarkY);
    canvasContext.lineWidth = lineWidth;
    canvasContext.strokeStyle = lineColor;
    canvasContext.stroke();
  }
}

export const defaultPoseLandmarkTypePairs: [
  PoseLandmarkType,
  PoseLandmarkType,
][] = [
  // Left arm
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.LeftElbow],
  [PoseLandmarkType.LeftElbow, PoseLandmarkType.LeftWrist],

  // Right arm
  [PoseLandmarkType.RightShoulder, PoseLandmarkType.RightElbow],
  [PoseLandmarkType.RightElbow, PoseLandmarkType.RightWrist],

  // Torso
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.RightShoulder],
  [PoseLandmarkType.LeftShoulder, PoseLandmarkType.LeftHip],
  [PoseLandmarkType.RightShoulder, PoseLandmarkType.RightHip],
  [PoseLandmarkType.LeftHip, PoseLandmarkType.RightHip],

  // Left leg
  [PoseLandmarkType.LeftHip, PoseLandmarkType.LeftKnee],
  [PoseLandmarkType.LeftKnee, PoseLandmarkType.LeftAnkle],

  // Right leg
  [PoseLandmarkType.RightHip, PoseLandmarkType.RightKnee],
  [PoseLandmarkType.RightKnee, PoseLandmarkType.RightAnkle],
];
