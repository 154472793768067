// Import the functions you need from the SDKs you need
import React from 'react';
import './TopAppBar.css'; // Create a CSS file for styling

// input: className
const TopAppBar: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`top-app-bar ${className}`}>
      <div className="title">A.I. Office Sitting Posture Tracking Demo</div>
      <div className="logos">
        <img src="bupa/bupa-logo-blue-bg.svg" alt="Blua Health Logo" className="blua-logo" />
        <img src="bupa/separator.svg" alt="Separator" className="separator" />
        <img src="bupa/gofa.svg" alt="Gofa Logo" className="gofa-logo" />
      </div>
    </div>
  );
};

export default TopAppBar;