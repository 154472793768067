import { PoseLandmarkMap } from "../models";
import { PoseSimilarityEvaluator } from "../similarity";

type KeyframeBasedRepCounterOptions = {
  poseSimilarityEvaluator: PoseSimilarityEvaluator;
  poseSimilarityThreshold: number;
  tgtKeyframePoseLandmarkMaps: PoseLandmarkMap[];
  onIncrementRepCount?: (repCount: number) => void;
};

export class KeyframeBasedRepCounter {
  /**
   * The pose similarity evaluator.
   * It is used to evaluate the similarity between user's current pose and the target keyframe.
   */
  poseSimilarityEvaluator: PoseSimilarityEvaluator;

  /**
   * The user will complete the current keyframe
   * if the similarity score is greater than this threshold.
   */
  poseSimilarityThreshold: number;

  /**
   * List of target keyframe pose landmark maps.
   */
  tgtKeyframePoseLandmarkMaps: PoseLandmarkMap[];

  /**
   * Callback function when rep count is incremented.
   */
  onIncrementRepCount?: (repCount: number) => void;

  /**
   * Rep count.
   */
  #count: number;

  /**
   * Index of the current keyframe.
   */
  #keyframeIndex: number;

  /**
   * The similarity score between user's current pose and the target keyframe.
   */
  #poseSimilarity?: number;

  #didRepStart: boolean;
  #repStartTimestamps: Set<number>;

  constructor({
    poseSimilarityEvaluator,
    poseSimilarityThreshold,
    tgtKeyframePoseLandmarkMaps,
    onIncrementRepCount,
  }: KeyframeBasedRepCounterOptions) {
    this.poseSimilarityEvaluator = poseSimilarityEvaluator;
    this.poseSimilarityThreshold = poseSimilarityThreshold;
    this.tgtKeyframePoseLandmarkMaps = tgtKeyframePoseLandmarkMaps;
    this.onIncrementRepCount = onIncrementRepCount;

    this.#count = 0;
    this.#keyframeIndex = 0;
    this.#didRepStart = false;
    this.#repStartTimestamps = new Set<number>();
  }

  /**
   * Current rep count.
   *
   * @return {number} The current rep count.
   */
  get count(): number {
    return this.#count;
  }

  /**
   * The similarity score between user's current pose and the target keyframe.
   *
   * @return {number | undefined} The similarity score between user's current pose and the target keyframe.
   * It is `undefined` if not yet computed.
   */
  get poseSimilarity(): number | undefined {
    return this.#poseSimilarity;
  }

  /**
   * Get the index of the current keyframe.
   *
   * @return {number} The index of the current keyframe.
   */
  get currentTgtKeyframeIndex(): number {
    return this.#keyframeIndex;
  }

  /**
   * Get the current pose landmark map for the target keyframe.
   *
   * @return {PoseLandmarkMap} The current pose landmark map for the target keyframe.
   */
  get currentTgtKeyframePoseLandmarkMap(): PoseLandmarkMap {
    return this.tgtKeyframePoseLandmarkMaps[this.#keyframeIndex];
  }

  observe(poseLandmarkMap: PoseLandmarkMap) {
    // Get the target keyframe pose landmark map
    const tgtKeyframePoseLandmarkMap =
      this.tgtKeyframePoseLandmarkMaps[this.#keyframeIndex];

    // Evaluate the similarity
    const poseSimilarityEvaluationResult =
      this.poseSimilarityEvaluator.evaluate(
        poseLandmarkMap,
        tgtKeyframePoseLandmarkMap,
      );

    // Get the overall pose similarity
    const poseSimilarity = poseSimilarityEvaluationResult.poseSimilarity;

    // Update the pose similarity
    this.#poseSimilarity = poseSimilarity;

    // Do nothing if the similarity is too low
    if (poseSimilarity <= this.poseSimilarityThreshold) {
      return;
    }

    // The pose similarity is high enough in the context below

    if (this.#keyframeIndex === 0) {
      // Log
      // logger.info("Start rep");

      // The user has started a new rep
      this.#didRepStart = true;

      // Record the starting timestamp
      this.#repStartTimestamps.add(Date.now());

      setTimeout(() => {
        if (!this.#didRepStart) {
          return;
        }

        // Failed to complete the rep in time

        // Log
        console.log("Failed to complete rep in time");
      }, 5000);
    }

    // The user has completed the last keyframe
    if (this.#keyframeIndex === this.tgtKeyframePoseLandmarkMaps.length - 1) {
      // Increment the rep count
      this.#count++;

      // Callback
      this.onIncrementRepCount?.(this.#count);

      // Set the didRepStart flag to false
      this.#didRepStart = false;
    }

    // Move to the next keyframe
    this.#keyframeIndex =
      (this.#keyframeIndex + 1) % this.tgtKeyframePoseLandmarkMaps.length;
  }
}
