/**
 * The types of a pose landmark.
 */
export enum PoseLandmarkType {
  Nose = 0,
  LeftEyeInner = 1,
  LeftEye = 2,
  LeftEyeOuter = 3,
  RightEyeInner = 4,
  RightEye = 5,
  RightEyeOuter = 6,
  LeftEar = 7,
  RightEar = 8,
  MouthLeft = 9,
  MouthRight = 10,
  LeftShoulder = 11,
  RightShoulder = 12,
  LeftElbow = 13,
  RightElbow = 14,
  LeftWrist = 15,
  RightWrist = 16,
  LeftPinky = 17,
  RightPinky = 18,
  LeftIndex = 19,
  RightIndex = 20,
  LeftThumb = 21,
  RightThumb = 22,
  LeftHip = 23,
  RightHip = 24,
  LeftKnee = 25,
  RightKnee = 26,
  LeftAnkle = 27,
  RightAnkle = 28,
  LeftHeel = 29,
  RightHeel = 30,
  LeftFootIndex = 31,
  RightFootIndex = 32,
}

export function stringToPoseLandmarkType(value: string): PoseLandmarkType {
  switch (value) {
    case "NOSE":
      return PoseLandmarkType.Nose;
    case "LEFT_EYE_INNER":
      return PoseLandmarkType.LeftEyeInner;
    case "LEFT_EYE":
      return PoseLandmarkType.LeftEye;
    case "LEFT_EYE_OUTER":
      return PoseLandmarkType.LeftEyeOuter;
    case "RIGHT_EYE_INNER":
      return PoseLandmarkType.RightEyeInner;
    case "RIGHT_EYE":
      return PoseLandmarkType.RightEye;
    case "RIGHT_EYE_OUTER":
      return PoseLandmarkType.RightEyeOuter;
    case "LEFT_EAR":
      return PoseLandmarkType.LeftEar;
    case "RIGHT_EAR":
      return PoseLandmarkType.RightEar;
    case "MOUTH_LEFT":
      return PoseLandmarkType.MouthLeft;
    case "MOUTH_RIGHT":
      return PoseLandmarkType.MouthRight;
    case "LEFT_SHOULDER":
      return PoseLandmarkType.LeftShoulder;
    case "RIGHT_SHOULDER":
      return PoseLandmarkType.RightShoulder;
    case "LEFT_ELBOW":
      return PoseLandmarkType.LeftElbow;
    case "RIGHT_ELBOW":
      return PoseLandmarkType.RightElbow;
    case "LEFT_WRIST":
      return PoseLandmarkType.LeftWrist;
    case "RIGHT_WRIST":
      return PoseLandmarkType.RightWrist;
    case "LEFT_PINKY":
      return PoseLandmarkType.LeftPinky;
    case "RIGHT_PINKY":
      return PoseLandmarkType.RightPinky;
    case "LEFT_INDEX":
      return PoseLandmarkType.LeftIndex;
    case "RIGHT_INDEX":
      return PoseLandmarkType.RightIndex;
    case "LEFT_THUMB":
      return PoseLandmarkType.LeftThumb;
    case "RIGHT_THUMB":
      return PoseLandmarkType.RightThumb;
    case "LEFT_HIP":
      return PoseLandmarkType.LeftHip;
    case "RIGHT_HIP":
      return PoseLandmarkType.RightHip;
    case "LEFT_KNEE":
      return PoseLandmarkType.LeftKnee;
    case "RIGHT_KNEE":
      return PoseLandmarkType.RightKnee;
    case "LEFT_ANKLE":
      return PoseLandmarkType.LeftAnkle;
    case "RIGHT_ANKLE":
      return PoseLandmarkType.RightAnkle;
    case "LEFT_HEEL":
      return PoseLandmarkType.LeftHeel;
    case "RIGHT_HEEL":
      return PoseLandmarkType.RightHeel;
    case "LEFT_FOOT_INDEX":
      return PoseLandmarkType.LeftFootIndex;
    case "RIGHT_FOOT_INDEX":
      return PoseLandmarkType.RightFootIndex;
    default:
      throw new Error(`Invalid enum member: ${value}`);
  }
}
