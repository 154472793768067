export async function sleep(milliseconds: number) {
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const formatDate = (date: Date): string => {
  // Set the options
  // const options: Intl.DateTimeFormatOptions = {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // };

  // Format the date
  // return date.toLocaleString("en-GB", options);

  // yyyy-MM-dd HH:mm:ss
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  // const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
