import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ExerciseResult } from "../models";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { LocaleKeys } from "../models/localizable";
import { toLocalized } from "../utils/locale-util";

const HKPFA_EXERCISE_RESULT_COLLECTION_NAME = "HKPFAExerciseResults";

const { RangePicker } = DatePicker;

type ExerciseResultOverviewPanelProps = {
  exerciseResults: ExerciseResult[];
  onDateRangeChange?: (startDate: Date, endDate: Date) => void;
  styles?: React.CSSProperties;
  className?: string;
};

const ExerciseResultOverviewPanel: React.FC<
  ExerciseResultOverviewPanelProps
> = (props) => {
  const intl = useIntl();

  // Number of completed exercises
  const numCompletedExercises = props.exerciseResults.length;

  // Calculate the total elapsed time in seconds
  let totalElapsedTimeSec = 0;
  for (const exerciseResult of props.exerciseResults) {
    totalElapsedTimeSec += exerciseResult.elapsedTimeSec;
  }

  // Calculate the total rep count
  let totalRepCount = 0;
  for (const exerciseResult of props.exerciseResults) {
    totalRepCount += exerciseResult.targetCount;
  }

  // A map from exercise title to total rep count
  // const exerciseTitleToTotalRepCountMap = new Map<string, number>();

  // for (const exerciseResult of props.exerciseResults) {
  //   // Get the target count associated with the completed exercise
  //   const targetCount = exerciseResult.targetCount;

  //   // Add the target count to the map
  //   if (exerciseTitleToTotalRepCountMap.has(exerciseResult.title)) {
  //     exerciseTitleToTotalRepCountMap.set(
  //       exerciseResult.title,
  //       exerciseTitleToTotalRepCountMap.get(exerciseResult.title)! + targetCount
  //     );
  //   } else {
  //     // Set a new entry for the exercise
  //     exerciseTitleToTotalRepCountMap.set(exerciseResult.title, targetCount);
  //   }
  // }

  const exerciseTitleToTotalRepCountMap = useMemo(() => {
    const map = new Map<string, number>();
    for (const exerciseResult of props.exerciseResults) {
      if (exerciseResult.exerciseTitle) {
        const titleLocalized = toLocalized({
          localizable: exerciseResult.exerciseTitle,
          locale: intl.locale as LocaleKeys,
        });
        if (titleLocalized) {
          const targetCount = exerciseResult.targetCount;
          if (map.has(titleLocalized)) {
            map.set(titleLocalized, map.get(titleLocalized)! + targetCount);
          } else {
            map.set(titleLocalized, targetCount);
          }
        }
      }
    }
    return map;
  }, [props.exerciseResults, intl.locale]);

  const formatElapsedTime = (elapsedTimeSec: number): string => {
    // Get the hours, minutes and seconds
    const hours = Math.floor(elapsedTimeSec / 3600);
    const minutes = Math.floor((elapsedTimeSec % 3600) / 60);
    const seconds = elapsedTimeSec % 60;

    // Format the hours, minutes and seconds
    const hh = hours.toString().padStart(2, "0");
    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${hh}:${mm}:${ss}`;
  };

  return (
    <div className={props.className} style={props.styles}>
      {/* Overview title */}
      <div className="font-bold text-primary">
        <FormattedMessage id="overview" />
      </div>

      {/* Date range picker */}
      <div className="flex w-full flex-row">
        <RangePicker
          className="w-full min-w-[200px] border-0 focus:border-0"
          defaultValue={[dayjs(), dayjs()]}
          onPickerValueChange={(value) => {
            // Convert to Date objects
            const startDate = value[0].toDate();
            let endDate = value[1].toDate();

            // Add 1 day to the end date
            endDate.setDate(endDate.getDate() + 1);

            // Call the onDateRangeChange callback
            props.onDateRangeChange?.(startDate, endDate);
          }}
        />
      </div>

      {/* Total workout completed */}
      <FieldSummaryPanel
        title={intl.formatMessage({ id: "totalWorkoutCompleted" })}
        displayedValue={numCompletedExercises.toString()}
        icon={<FireIcon />}
      />

      {/* Total workout time */}
      <FieldSummaryPanel
        title={intl.formatMessage({ id: "totalWorkoutTime" })}
        displayedValue={formatElapsedTime(totalElapsedTimeSec)}
        icon={<StopWatchIcon />}
      />

      {/* Total rep count */}
      <FieldSummaryPanel
        title={intl.formatMessage({ id: "totalRepCount" })}
        displayedValue={totalRepCount.toString()}
        icon={<DumbbellIcon />}
      />

      {/* Total rep count by workout */}

      <p className="text-xs font-bold italic text-zinc-500">
        <FormattedMessage id="totalRepCountByWorkout" />
      </p>

      <div className="grid w-full grid-cols-2 gap-2">
        {Array.from(exerciseTitleToTotalRepCountMap.entries()).map(
          ([title, repCount]) => {
            return (
              <div
                className="flex flex-col justify-between rounded-md bg-white p-2"
                key={title}
              >
                <p className="text-xs font-normal italic text-zinc-500">
                  {title}
                </p>
                <p className="text-base font-bold text-zinc-800">{repCount}</p>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

type FieldSummaryPanelProps = {
  icon: JSX.Element;
  title: string;
  displayedValue: string;
};

const FieldSummaryPanel: React.FC<FieldSummaryPanelProps> = (props) => {
  return (
    <div className="flex w-full flex-row items-center justify-start gap-4 rounded bg-white p-2">
      {/* Icon */}
      {props.icon}

      <div className="flex flex-col justify-between">
        {/* Title */}
        <p className="text-xs font-normal italic text-zinc-500">
          {props.title}
        </p>

        {/* Displayed Value */}
        <p className="text-lg font-bold text-zinc-800">
          {props.displayedValue}
        </p>
      </div>
    </div>
  );
};

const FireIcon: React.FC = () => {
  return (
    <svg
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 23"
      fill="none"
    >
      <path
        d="M8.47 22.0933C8.14 22.0933 7.85 21.9233 7.67 21.6533C7.46 21.3233 7.49 20.8933 7.72 20.5833C8.1 20.0833 8.56 19.2133 8.56 17.8533C8.56 16.3733 7.83 14.6433 7.21 13.4533C6.6 14.6333 5.86 16.3633 5.86 17.8533C5.86 19.1833 6.29 20.0433 6.66 20.5333C6.82 20.6933 6.92 20.9633 6.89 21.2433C6.84 21.7233 6.44 22.0933 5.96 22.0933C5.89 22.0933 5.82 22.0933 5.76 22.0733C2.42 21.4133 0 18.4433 0 15.0033C0 11.8233 1.34 10.0333 2.64 8.30332C3.93 6.59332 5.14 4.97332 5.14 1.83332C5.14 1.58332 5.24 1.34332 5.41 1.17332C5.58 1.00332 5.83 0.90332 6.07 0.90332C7.65 0.90332 10.86 5.51332 10.89 5.56332C12.21 7.47332 14.42 11.2333 14.42 15.0133C14.42 18.4433 11.99 21.4233 8.64 22.0933C8.58 22.1033 8.52 22.1133 8.46 22.1133L8.47 22.0933ZM6.9 3.58332C6.51 6.27332 5.26 7.93332 4.15 9.42332C2.94 11.0433 1.89 12.4433 1.89 15.0033C1.89 16.7733 2.76 18.4133 4.17 19.3933C4.06 18.9033 4.01 18.3933 4.01 17.8533C4.01 14.7733 6.35 11.2133 6.45 11.0633C6.62 10.8033 6.92 10.6433 7.23 10.6433C7.54 10.6433 7.84 10.8033 8.01 11.0633C8.11 11.2133 10.45 14.7833 10.45 17.8533C10.45 18.3833 10.4 18.8933 10.29 19.3833C11.7 18.3933 12.57 16.7633 12.57 15.0033C12.57 10.7133 8.95 5.66332 6.9 3.59332V3.58332Z"
        fill="#FF3702"
      />
    </svg>
  );
};

const StopWatchIcon: React.FC = () => {
  return (
    <svg
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_601_17636)">
        <path
          d="M19.6693 8.02338C17.9773 6.05286 15.5841 4.81852 12.9982 4.5823V2.71966H13.9064C14.2308 2.71966 14.5306 2.54659 14.693 2.26551C14.8551 1.9844 14.8551 1.63826 14.693 1.35734C14.5308 1.07641 14.2308 0.90332 13.9064 0.90332H10.2737C9.94904 0.90332 9.6494 1.07639 9.48706 1.35747C9.32491 1.6384 9.32491 1.98472 9.48706 2.26564C9.6492 2.54657 9.94902 2.71979 10.2737 2.71979H11.1818V4.58243C8.7015 4.81117 6.39572 5.9571 4.71629 7.79634C3.03642 9.63541 2.10361 12.0353 2.10032 14.526L2.09998 14.5262C2.09876 17.1234 3.10923 19.6195 4.91674 21.4841C6.72442 23.3492 9.1874 24.4368 11.7836 24.5164C14.3796 24.5962 16.9049 23.6617 18.8232 21.9109C20.7417 20.1604 21.9034 17.7313 22.0612 15.139C22.2194 12.5465 21.3615 9.9942 19.6695 8.02355L19.6693 8.02338ZM17.8694 20.3059C16.3364 21.8387 14.2576 22.6997 12.0897 22.6997C9.92194 22.6997 7.84301 21.8385 6.31008 20.3059C4.77729 18.7729 3.91609 16.694 3.91609 14.5262C3.91851 12.3591 4.78057 10.2814 6.31283 8.74922C7.84496 7.21691 9.92261 6.35487 12.0898 6.35247L12.0897 6.35264C14.2575 6.35264 16.3364 7.2138 17.8693 8.74664C19.4021 10.2796 20.2633 12.3585 20.2633 14.5263C20.2633 16.694 19.4022 18.773 17.8693 20.306L17.8694 20.3059Z"
          fill="#FF3702"
        />
        <path
          d="M12.9982 14.1503V10.8936C12.9982 10.5689 12.8252 10.2693 12.5441 10.107C12.2632 9.94482 11.9168 9.94482 11.6359 10.107C11.355 10.2691 11.1818 10.5689 11.1818 10.8936V14.5263C11.1818 14.7671 11.2775 14.9979 11.448 15.1682L13.2643 16.9846C13.4949 17.2072 13.8261 17.292 14.1353 17.2072C14.4445 17.1223 14.6861 16.8807 14.7711 16.5715C14.8559 16.2621 14.7711 15.9311 14.5482 15.7005L12.9982 14.1503Z"
          fill="#FF3702"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_17636">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.90332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const DumbbellIcon: React.FC = () => {
  return (
    <svg
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_601_17640)">
        <path
          d="M20.57 15.7633L22 14.3333L20.57 12.9033L17 16.4733L8.43 7.90332L12 4.33332L10.57 2.90332L9.14 4.33332L7.71 2.90332L5.57 5.04332L4.14 3.61332L2.71 5.04332L4.14 6.47332L2 8.61332L3.43 10.0433L2 11.4733L3.43 12.9033L7 9.33332L15.57 17.9033L12 21.4733L13.43 22.9033L14.86 21.4733L16.29 22.9033L18.43 20.7633L19.86 22.1933L21.29 20.7633L19.86 19.3333L22 17.1933L20.57 15.7633Z"
          fill="#FF3702"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_17640">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.90332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExerciseResultOverviewPanel;
