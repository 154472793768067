import { useNavigate } from "react-router-dom";

export function Error404Page(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-6">
      <img src="/gofa-logo.svg" alt="GOFA Logo" className="mb-6 h-32 w-32" />
      <h1 className="text-6xl font-extrabold text-gray-800">404</h1>
      <p className="mt-4 text-xl text-gray-600">
        Oops! The page you are looking for does not exist.
      </p>
      <button
        className="mt-6 rounded bg-blue-600 px-4 py-2 font-bold text-white transition duration-300 hover:bg-blue-800"
        onClick={() => navigate("/")}
      >
        Go back to home page
      </button>
    </div>
  );
}
