import { RefObject, useEffect, useState } from "react";

export function WebcamView({
  videoRef,
  styles,
}: {
  videoRef: RefObject<HTMLVideoElement>;
  styles?: React.CSSProperties;
}) {
  const [videoStream, setVideoStream] = useState<MediaStream>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const width = parseInt(urlParams.get('width') ?? '1920', 10); // default width
    const height = parseInt(urlParams.get('height') ?? '1080', 10); // default height

    if (navigator.mediaDevices) {
      if (!videoRef.current)
        throw new Error("videoRef is not pointing to any video element");

      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: width,
            height: height,
          },
        })
        .then((stream) => {
          videoRef.current!.srcObject = stream;
          setVideoStream(stream);
        })
        .then(() => {
          return () => {
            console.log("Unmounting webcam view");
            console.log(`video element: ${videoRef.current}`);
          };
        });
    } else {
      alert("Your browser does not support HTML5 videos");
    }
  }, [videoRef]);

  return (
    <video
      id="webcam"
      ref={videoRef}
      playsInline
      autoPlay
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transform: "scaleX(-1)",
        ...styles,
      }}
    />
  );
}