import { createContext, ReactNode } from "react";
import * as firebase from "firebase/auth";

export interface AuthContextProps {
  currentUser: firebase.User | null;
}

export interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined,
);
