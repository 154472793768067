export class Stopwatch {
  #elapsedTimeSec = 0;
  #timerId = 0;
  #onTick?: (elapsedTimeSec: number) => void;
  #onStart?: () => void;
  #onStop?: (elapsedTimeSec: number) => void;
  #onReset?: (elapsedTimeSec: number) => void;

  constructor({
    onTick,
    onStart,
    onStop,
    onReset,
  }: {
    onTick?: (elapsedTimeSec: number) => void;
    onStart?: () => void;
    onStop?: (elapsedTimeSec: number) => void;
    onReset?: (elapsedTimeSec: number) => void;
  } = {}) {
    this.#onTick = onTick;
    this.#onStart = onStart;
    this.#onStop = onStop;
    this.#onReset = onReset;
  }

  /**
   * Gets the elapsed time in seconds.
   */
  get elapsedTimeSec(): number {
    return this.#elapsedTimeSec;
  }

  start() {
    // Clear existing timer
    clearInterval(this.#timerId);

    // Start new timer
    // Update elapsed time every second
    this.#timerId = window.setInterval(() => {
      this.#elapsedTimeSec += 1;

      // Call onTick callback if it exists
      if (this.#onTick) {
        this.#onTick(this.#elapsedTimeSec);
      }
    }, 1000);

    // Call onStart callback if it exists
    if (this.#onStart) {
      this.#onStart();
    }
  }

  stop() {
    // Clear existing timer
    clearInterval(this.#timerId);

    // Call onStop callback if it exists
    if (this.#onStop) {
      this.#onStop(this.#elapsedTimeSec);
    }
  }

  reset() {
    // Clear existing timer
    clearInterval(this.#timerId);

    // Call onReset callback if it exists
    if (this.#onReset) {
      this.#onReset(this.#elapsedTimeSec);
    }

    // Clear elapsed time
    this.#elapsedTimeSec = 0;
  }
}
