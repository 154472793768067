import React from "react";
import { FormattedMessage } from "react-intl";
import { signOut } from "firebase/auth";
import { AUTH } from "../../firebase/constants";
import LocaleSwitcher from "../LocaleSwitcher";

// add props. includeLogout
interface TopRightButtonsProps {
  includeLogout?: boolean;
}

const TopRightButtons: React.FC<TopRightButtonsProps> = ({ includeLogout }) => {
  return (
    <div className="flex items-center gap-2 sm:gap-5">
      <LocaleSwitcher />

      {/* Logout button fixed at the screen top right  */}
      {includeLogout && (
        <div className="flex items-center gap-2 sm:gap-5">
          {/* a separator | */}
          <div className="text-xs font-normal text-neutral-400">|</div>
          <button
            className="font-['Open Sans'] flex cursor-pointer text-xs font-normal text-neutral-600"
            onClick={() => {
              // Call firebase signOut function
              signOut(AUTH);

              // Redirect to login page
              window.location.href = "/login";
            }}
          >
            <FormattedMessage id="logout" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TopRightButtons;
