import { useEffect, useState } from "react";
import { Stopwatch } from "../controllers";

export function useStopwatch(): {
  stopwatch: Stopwatch;
  elapsedTimeSec: number;
} {
  const [stopwatch, setStopwatch] = useState<Stopwatch>(
    new Stopwatch({
      onTick: (elapsedTimeSec) => {
        // Update the elapsed time
        setElapsedTimeSec(elapsedTimeSec);
      },
      onStart: () => {
        console.log("Started");
      },
      onStop: (_) => {
        console.log("Stopped");
      },
      onReset: (_) => {
        // Reset the elapsed time
        setElapsedTimeSec(0);

        // Log
        console.log("Reset");
      },
    }),
  );

  const [elapsedTimeSec, setElapsedTimeSec] = useState(0);

  return {
    stopwatch: stopwatch,
    elapsedTimeSec: elapsedTimeSec,
  };
}
