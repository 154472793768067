import { useRef } from "react";
import ReactPlayer from "react-player";

type VideoPlayerProps = {
  url: string;
  playing?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
};

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  // A reference to the react player component
  const reactPlayerRef = useRef<ReactPlayer>(null);

  return (
    <div className="h-full w-full">
      {/* The following style will be applied to the wrapped video element */}
      <style>
        {`
            .react-player video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          `}
      </style>
      <ReactPlayer
        className="react-player"
        ref={reactPlayerRef}
        url={props.url}
        playsinline
        pip={false}
        controls={props.controls}
        playing={props.playing}
        loop={props.loop}
        muted={props.muted}
        width="100%"
        height="100%"
      />
    </div>
  );
};
