import { PoseLandmarkType } from "./pose-landmark-type";
import { PoseLandmark } from "./pose-landmark";
import { stringToPoseLandmarkType } from "./pose-landmark-type";

// export type PoseLandmarkMap = Map<PoseLandmarkType, PoseLandmark>;

export class PoseLandmarkMap extends Map<PoseLandmarkType, PoseLandmark> {

  static fromJson(json: object) {
    // Create an empty pose landmark map
    const poseLandmarkMap = new PoseLandmarkMap();

    for (const [key, value] of Object.entries(json)) {
      // Convert the key (string) to PoseLandmarkType
      const poseLandmarkType = stringToPoseLandmarkType(key as string);

      // Convert the value (object) to PoseLandmark
      const poseLandmark = PoseLandmark.fromJson(value as object);

      // Add to the pose landmark map
      poseLandmarkMap.set(poseLandmarkType, poseLandmark);
    }

    return poseLandmarkMap;
  }

  get(key: PoseLandmarkType) {
    return super.get(key);
  }

  set(key: PoseLandmarkType, value: PoseLandmark) {
    return super.set(key, value);
  }
}
