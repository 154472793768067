import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AUTH } from "../firebase/constants";
import { FormattedMessage, useIntl } from "react-intl";
import BlankLayout from "../components/layout/BlankLayout";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      // set loading to true
      setLoading(true);

      await signInWithEmailAndPassword(AUTH, email, password);

      setError(null);

      // redirect to home page
      // window.location.href = "/";

      // navigate("/health-demo", { replace: true });

      // if query string is present, redirect to the query string
      const searchParams = new URLSearchParams(window.location.search);

      // log searchParams
      console.log(searchParams);

      if (searchParams.has("redirect")) {
        const redirect = searchParams.get("redirect");
        if (redirect) {
          navigate(redirect);
        }
      }
      else {
        navigate("/health-demo", { replace: true });
      }

    } catch (err: any) {
      setError(err.message);
    } finally {
      // set loading to false
      setLoading(false);
    }
  };

  return (
    // <div className="min-h-screen flex items-center justify-center bg-gray-100 px-3">

    //   {/* position top right corner a LanguageSwitcher */}
    //   <div className="absolute top-0 right-0 m-4 p-2 text-white rounded-lg">
    //     <TopRightButtons includeLogout={false} />
    //   </div>

    // </div>
    <BlankLayout includeLogout={false}>
      {/* loading spinner */}
      {loading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="border-gofa-red h-32 w-32 animate-spin rounded-full border-b-2 border-t-2"></div>
        </div>
      )}
      {/* login form */}
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
        {/* gofa logo here */}
        <img
          src="/gofa-logo.jpg"
          alt="gofa logo"
          className="mx-auto w-20 py-10"
        />
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-3"
          autoComplete="on"
        >
          <div>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={intl.formatMessage({ id: "email" })}
              required
              className="w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring"
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={intl.formatMessage({ id: "password" })}
              required
              className="flex w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring"
            />
          </div>
          <button
            type="submit"
            className="bg-gofa-red hover:bg-gofa-red-hover focus:bg-gofa-red-darker mt-3 flex w-full justify-center rounded-lg py-2 text-white focus:outline-none"
          >
            <FormattedMessage id="login" />
          </button>
        </form>
        {error && <p className="mt-4 text-center text-red-600">{error}</p>}
      </div>
    </BlankLayout>
  );
};

export default LoginPage;
