import {
  PoseLandmarkType,
  PoseLandmark,
  PoseLandmarkMap,
  Pose,
} from "../models";

/**
 * Returns a mirrored pose based on the input pose.
 *
 * @param {Pose} pose - the original pose to be mirrored
 * @return {Pose} a new mirrored pose
 */
export function mirrorPose(pose: Pose): Pose {
  return new Pose({
    frameLandmarkMap: mirrorPoseFrameLandmarkMap(pose.frameLandmarkMap),
    worldLandmarkMap: mirrorPoseWorldLandmarkMap(pose.worldLandmarkMap),
  });
}

/**
 * Mirror the given pose frame landmark map.
 *
 * The x coordinate of each mirrored pose frame landmark is set to 1 - x.
 *
 * @param {PoseLandmarkMap} poseFrameLandmarkMap - The original pose frame landmark map to be mirrored
 * @return {PoseLandmarkMap} The mirrored pose frame landmark map
 */
export function mirrorPoseFrameLandmarkMap(
  poseFrameLandmarkMap: PoseLandmarkMap,
): PoseLandmarkMap {
  // Initialize a mirrored pose frame landmark map
  const mirroredPoseFrameLandmarkMap: PoseLandmarkMap = new Map();

  // Mirror each pose landmark
  poseFrameLandmarkMap.forEach((poseLandmark, poseLandmarkType) => {
    mirroredPoseFrameLandmarkMap.set(
      getMirroredPoseLandmarkType(poseLandmarkType),
      new PoseLandmark(1 - poseLandmark.x, poseLandmark.y, poseLandmark.z),
    );
  });

  return mirroredPoseFrameLandmarkMap;
}

/**
 * Mirror the pose world landmark map.
 *
 * The x coordinate of each mirrored pose world landmark is set to -x.
 *
 * @param {PoseLandmarkMap} poseWorldLandmarkMap - The original pose landmark map to be mirrored
 * @return {PoseLandmarkMap} The mirrored pose world landmark map
 */
export function mirrorPoseWorldLandmarkMap(
  poseWorldLandmarkMap: PoseLandmarkMap,
): PoseLandmarkMap {
  // Initialize a mirrored pose world landmark map
  const mirroredPoseWorldLandmarkMap: PoseLandmarkMap = new Map();

  // Mirror each pose landmark
  poseWorldLandmarkMap.forEach((poseLandmark, poseLandmarkType) => {
    mirroredPoseWorldLandmarkMap.set(
      getMirroredPoseLandmarkType(poseLandmarkType),
      new PoseLandmark(-poseLandmark.x, poseLandmark.y, poseLandmark.z),
    );
  });

  return mirroredPoseWorldLandmarkMap;
}

/**
 * Returns the mirrored version of the given PoseLandmarkType.
 *
 * @param {PoseLandmarkType} landmarkType - The PoseLandmarkType to be mirrored.
 * @return {PoseLandmarkType} The mirrored PoseLandmarkType.
 */
function getMirroredPoseLandmarkType(
  landmarkType: PoseLandmarkType,
): PoseLandmarkType {
  switch (landmarkType) {
    case PoseLandmarkType.Nose:
      return PoseLandmarkType.Nose;
    case PoseLandmarkType.LeftEyeInner:
      return PoseLandmarkType.RightEyeInner;
    case PoseLandmarkType.LeftEye:
      return PoseLandmarkType.RightEye;
    case PoseLandmarkType.LeftEyeOuter:
      return PoseLandmarkType.RightEyeOuter;
    case PoseLandmarkType.RightEyeInner:
      return PoseLandmarkType.LeftEyeInner;
    case PoseLandmarkType.RightEye:
      return PoseLandmarkType.LeftEye;
    case PoseLandmarkType.RightEyeOuter:
      return PoseLandmarkType.LeftEyeOuter;
    case PoseLandmarkType.LeftEar:
      return PoseLandmarkType.RightEar;
    case PoseLandmarkType.RightEar:
      return PoseLandmarkType.LeftEar;
    case PoseLandmarkType.MouthLeft:
      return PoseLandmarkType.MouthRight;
    case PoseLandmarkType.MouthRight:
      return PoseLandmarkType.MouthLeft;
    case PoseLandmarkType.LeftShoulder:
      return PoseLandmarkType.RightShoulder;
    case PoseLandmarkType.RightShoulder:
      return PoseLandmarkType.LeftShoulder;
    case PoseLandmarkType.LeftElbow:
      return PoseLandmarkType.RightElbow;
    case PoseLandmarkType.RightElbow:
      return PoseLandmarkType.LeftElbow;
    case PoseLandmarkType.LeftWrist:
      return PoseLandmarkType.RightWrist;
    case PoseLandmarkType.RightWrist:
      return PoseLandmarkType.LeftWrist;
    case PoseLandmarkType.LeftPinky:
      return PoseLandmarkType.RightPinky;
    case PoseLandmarkType.RightPinky:
      return PoseLandmarkType.LeftPinky;
    case PoseLandmarkType.LeftIndex:
      return PoseLandmarkType.RightIndex;
    case PoseLandmarkType.RightIndex:
      return PoseLandmarkType.LeftIndex;
    case PoseLandmarkType.LeftThumb:
      return PoseLandmarkType.RightThumb;
    case PoseLandmarkType.RightThumb:
      return PoseLandmarkType.LeftThumb;
    case PoseLandmarkType.LeftHip:
      return PoseLandmarkType.RightHip;
    case PoseLandmarkType.RightHip:
      return PoseLandmarkType.LeftHip;
    case PoseLandmarkType.LeftKnee:
      return PoseLandmarkType.RightKnee;
    case PoseLandmarkType.RightKnee:
      return PoseLandmarkType.LeftKnee;
    case PoseLandmarkType.LeftAnkle:
      return PoseLandmarkType.RightAnkle;
    case PoseLandmarkType.RightAnkle:
      return PoseLandmarkType.LeftAnkle;
    case PoseLandmarkType.LeftHeel:
      return PoseLandmarkType.RightHeel;
    case PoseLandmarkType.RightHeel:
      return PoseLandmarkType.LeftHeel;
    case PoseLandmarkType.LeftFootIndex:
      return PoseLandmarkType.RightFootIndex;
    case PoseLandmarkType.RightFootIndex:
      return PoseLandmarkType.LeftFootIndex;
    default:
      throw new Error(`Unknown PoseLandmarkType: ${landmarkType}`);
  }
}
