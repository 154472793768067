import React, { } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Overlay } from "./Overlay";


type LoadingOverlayProps = {
  onCompleteLoading?: () => void;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = () => {
  return (
    <Overlay style={{ backgroundColor: "rgba(51, 51, 51, 0.95)" }}>
      {/* Countdown Number */}
      <div className="absolute flex h-1/4 w-auto items-center justify-center hover:cursor-pointer">
        <CircularProgress className="" color="primary" size={90} />
      </div>
    </Overlay>
  );
};

export default LoadingOverlay;
