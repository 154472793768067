import { RefObject, useEffect, useRef, useState } from "react";
import {
  PoseFrameLandmarkMapPainter,
  DefaultPoseFrameLandmarkMapPainter,
} from "../painting-utils";

/**
 * Creates a pose frame landmark map painter in the state, and then sets it.
 *
 * @param canvasRef - The reference to the canvas element.
 * @param createPoseLandmarkMapPainter - The function to create the pose landmark map painter.
 * @returns {PoseFrameLandmarkMapPainter | undefined} The pose frame landmark map painter.
 * Returns `undefined` if the pose frame landmark map painter is not yet set up.
 */
export function usePoseFrameLandmarkMapPainter<
  T extends PoseFrameLandmarkMapPainter = DefaultPoseFrameLandmarkMapPainter,
>(
  canvasRef: RefObject<HTMLCanvasElement>,
  createPoseLandmarkMapPainter: {
    new (context: CanvasRenderingContext2D): T;
  },
): T | undefined {
  // The painter
  const [poseFrameLandmarkMapPainter, setPoseFrameLandmarkMapPainter] =
    useState<T>();

  // Set up the painter
  useEffect(() => {
    // Get the canvas element
    const canvas = canvasRef.current;
    if (!canvas) throw new Error("canvasRef is not pointing to any canvas");

    // Get the canvas context
    const canvasContext = canvas.getContext("2d");
    if (!canvasContext) throw new Error("Failed to get canvas context");

    setPoseFrameLandmarkMapPainter(
      new createPoseLandmarkMapPainter(canvasContext),
    );
  }, []);

  return poseFrameLandmarkMapPainter;
}

/**
 * Creates a pose frame landmark map painter in the state, sets it, and returns a reference to it.
 *
 *
 * @param canvasRef - The reference to the canvas element.
 * @param createPoseLandmarkMapPainter - The function to create the pose landmark map painter.
 * @returns {RefObject<T | undefined>} The pose frame landmark map painter reference.
 * Returns `undefined` if the pose frame landmark map painter is not yet set up.
 */
export function usePoseFrameLandmarkMapPainterRef<
  T extends PoseFrameLandmarkMapPainter = DefaultPoseFrameLandmarkMapPainter,
>(
  canvasRef: RefObject<HTMLCanvasElement>,
  createPoseLandmarkMapPainter: {
    new (context: CanvasRenderingContext2D): T;
  },
): RefObject<T | undefined> {
  // Create a reference to the pose frame landmark map painter
  const poseFrameLandmarkMapPainterRef = useRef<T>();

  // Create the pose frame landmark map painter
  const poseFrameLandmarkMapPainter = usePoseFrameLandmarkMapPainter<T>(
    canvasRef,
    createPoseLandmarkMapPainter,
  );

  // Set the pose frame landmark map painter
  useEffect(() => {
    poseFrameLandmarkMapPainterRef.current = poseFrameLandmarkMapPainter;
  }, [poseFrameLandmarkMapPainter]);

  return poseFrameLandmarkMapPainterRef;
}
