import "./App.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  ExerciseSelectionPage,
  ExerciseRepCountPage,
  ExerciseResultPage,
  RepCountPage,
  PoseDetectionPage,
} from "./pages";
import { AuthProvider } from "./auth/AuthProvider";
import LoginPage from "./pages/LoginPage";
import Playground from "./pages/Playground";
import { IntlManager } from "./intl-manager";
import { LocaleProvider, useLocale } from "./context/LocaleContext";
import { ExerciseHistoryPage } from "./pages";
import { ConfigProvider } from "antd";

import enGB from "antd/locale/en_GB";
import TestSoundPage from "./pages/TestSoundPage";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Error404Page } from "./pages/Error404Page";
import { Helmet } from "react-helmet";


// console.log package.json version
import packageJson from "../package.json";
import { SitWellPage } from './pages/SitWell/SitWellPage';
import AiCoachPage from './pages/AiCoachPage';
import PrivateRoute from './components/PrivateRoute';

console.log(`Version: ${packageJson.version}`);


const theme = createTheme({
  palette: {
    primary: {
      main: "#ff3702",
    },
    secondary: {
      main: "#ff3702",
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#ff3702",

            // Alias Token
            colorBgContainer: "#ffffff",
          },
        }}
        locale={enGB}
      >
        <LocaleProvider>
          <MainApp />
        </LocaleProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

const MainApp: React.FC = () => {
  const { locale } = useLocale();

  return (
    <IntlManager locale={locale}>
      <Helmet>
        <title>GOFA App</title>
        <meta
          name="description"
          content="Innovative health tech for precise workouts. Real-time motion tracking, gesture recognition, and personalized coaching."
        />
      </Helmet>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="playground" element={<Playground />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="t" element={<RepCountPage />} />
            <Route path="c" element={<PoseDetectionPage />} />
            <Route path="s" element={<TestSoundPage />} />
            <Route path="sit-well" element={<SitWellPage />} />
            <Route path="health-demo">
              {" "}
              {/* element={<PrivateRoute />}> */}
              {/* <Route path="/" element={<HomePage />} /> */}
              <Route path="" element={<ExerciseSelectionPage />} />
              <Route
                path="exercise-rep-count"
                element={<ExerciseRepCountPage />}
              />
              <Route path="exercise-result" element={<ExerciseResultPage />} />
              <Route
                path="exercise-history"
                element={<ExerciseHistoryPage />}
              />
            </Route>
            {/* PrivateRoute */}
            <Route element={<PrivateRoute />}>
              <Route path="ai-coach" element={<AiCoachPage />} />
            </Route>
            <Route path="404" element={<Error404Page />} />
            {/* redirect / to /login */}
            <Route path="" element={<Navigate to="/login" />} />
            {/* fallback to 404 */}
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </IntlManager>
  );
};

export default App;
