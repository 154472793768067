import { useEffect } from "react";
import { StopwatchView } from "../components";
// import { Stopwatch } from "ts-stopwatch";
import { useStopwatch } from "../hooks";

export default function Playground(): JSX.Element {
  const { stopwatch, elapsedTimeSec } = useStopwatch();

  useEffect(() => {
    if (!stopwatch) return;

    stopwatch.start();
  }, [stopwatch]);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <button onClick={() => stopwatch?.start()}>Start</button>
      <button onClick={() => stopwatch?.stop()}>Stop</button>
      <button onClick={() => stopwatch?.reset()}>Reset</button>
      <StopwatchView elapsedTimeSec={elapsedTimeSec} />
    </div>
  );
}
