// src/components/Footer.tsx
import React from "react";
import packageJson from "../../../package.json";

// props
interface FooterProps {
  className?: string;
}

const MainFooter: React.FC<FooterProps> = ({ className }) => {
  const version = packageJson.version;

  return (
    <footer
      className={
        className ? className : "pt-2 text-center text-xs text-gray-500"
      }
    >
      {/*
        show package.json version
        Copyright: GF Lab International Limited
      */}
      <div className="flex flex-wrap justify-center space-x-1">
        <span>GOFA Web App</span>
        <span>| version {version}</span>
        <span>
          | &copy; {new Date().getFullYear()} GF Lab International Limited
        </span>
      </div>
    </footer>
  );
};

export default MainFooter;
