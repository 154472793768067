import { useEffect, useState } from "react";
import { ExerciseResult } from "../models";
import { FIRESTORE } from "../firebase";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import { FormattedMessage, useIntl } from "react-intl";
import BlankLayout from "../components/layout/BlankLayout";
import { toLocalized } from "../utils/locale-util";
import { LocaleKeys } from "../models/localizable";

import TextField from "@mui/material/TextField";

const HKPFA_EXERCISES_COLLECTION_NAME = "HKPFAExercises";
const HKPFA_EXERCISES_RESULT_COLLECTION_NAME = "HKPFAExerciseResults";

export const ExerciseResultPage: React.FC = () => {
  // For internationalization
  const intl = useIntl();

  // Input player name
  const [playerName, setPlayerName] = useState<string>();

  /**
   * Gets the exercise result from the URL.
   *
   * @returns The exercise result
   */
  const getExerciseResult = async () => {
    // Get the query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get the query parameter value
    const exerciseResultJson = urlSearchParams.get("exerciseResult");
    if (!exerciseResultJson)
      throw new Error("Exercise result not found in URL");

    // Parse to exercise
    const exerciseResult = JSON.parse(exerciseResultJson) as ExerciseResult;

    // Get the current date, and
    // set the date to the exercise result
    exerciseResult.date = new Date();

    // if exerciseResult.exerciseId is not set, throw
    if (!exerciseResult.exerciseId)
      throw new Error("Exercise ID not found in exercise result");

    // fetch the exercise
    const querySnapshot = await getDocs(
      query(
        collection(FIRESTORE, HKPFA_EXERCISES_COLLECTION_NAME),
        where("id", "==", exerciseResult.exerciseId),
      ),
    );

    // Get the exercise
    const exerciseDoc = querySnapshot.docs[0];

    // Get the exercise data
    const exerciseData = exerciseDoc.data();

    // Set the exercise title
    exerciseResult.exerciseTitle = exerciseData.exerciseTitle;

    return exerciseResult;
  };

  function onPlayerNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Get the input value
    const playerName = event.target.value;

    // Strip the white space
    const trimmedPlayerName = playerName.trim();

    // Set the player name
    setPlayerName(trimmedPlayerName);
  }

  const generatePlayerName = async () => {
    // Get all documents with playName
    const querySnapshot = await getDocs(
      query(
        collection(FIRESTORE, HKPFA_EXERCISES_RESULT_COLLECTION_NAME),
        where("playerName", "!=", null),
      ),
    );

    // Get the number of documents
    const numDocs = querySnapshot.size;

    // Generate the player's name
    const playerName = `Player ${numDocs + 1}`;

    return playerName;
  };

  const addExerciseResultToDatabase = async (
    exerciseResult: ExerciseResult,
  ) => {
    // Set the player name
    if (playerName) {
      exerciseResult.playerName = playerName;
    } else {
      // Generate a player name
      exerciseResult.playerName = await generatePlayerName();
    }

    // Add the exercise result to the database
    await addDoc(
      collection(FIRESTORE, HKPFA_EXERCISES_RESULT_COLLECTION_NAME),
      exerciseResult,
    );
  };

  const addExerciseResultAndNavigate = () => {
    // No result
    if (!exerciseResult) {
      // Navigate to the home page
      navigateToHome();
      return;
    }

    // Add the exercise result to the database
    addExerciseResultToDatabase(exerciseResult).then(() => {
      // Navigate to the home page
      navigateToHome();
    });
  };

  const initialize = async () => {
    // Get the exercise result from the URL
    const result = await getExerciseResult();

    console.log(exerciseResult);

    // Set the exercise result
    setExerciseResult(result);
  };

  function navigateToHome() {
    // Clear the history
    window.history.replaceState(null, document.title, "/");

    // Navigate to the home page
    window.location.href = "/health-demo";
  }

  function handlePopState(event: PopStateEvent) {
    if (event.state) {
      navigateToHome();
    }
  }

  useEffect(() => {
    // Initialize
    initialize();

    // Add a popstate listener to quit the exercise when the user navigates back
    window.addEventListener("popstate", handlePopState);

    // Clean up
    return () => {
      // Remove the popstate listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const [exerciseResult, setExerciseResult] = useState<ExerciseResult>();

  return (
    <BlankLayout includeLogout={true}>
      <div className="flex flex-col text-center">
        <span className="font-['Open Sans'] my-1 text-2xl font-extrabold italic text-zinc-800">
          <FormattedMessage id="gofaHealthAltTitle" />
        </span>
        <span className="font-['Open Sans'] my-1 text-4xl font-extrabold italic text-orange-600">
          <FormattedMessage id="results" />
        </span>
      </div>

      {/* Exercise result */}
      <div className="svg-background my-5 flex w-full max-w-sm flex-col bg-white py-2 shadow-md sm:max-w-lg md:w-7/12">
        {/* Title */}
        <div className="flex flex-col items-center">
          <div className="WorkoutName font-['Open Sans'] text-base font-normal leading-snug text-neutral-400">
            <FormattedMessage id="workoutName" />
          </div>
          <div className="font-['Open Sans'] my-3 w-96 text-center text-3xl font-bold leading-9 text-zinc-800">
            {exerciseResult &&
              `${toLocalized({
                localizable: exerciseResult.exerciseTitle,
                locale: intl.locale as LocaleKeys,
              })}`}
          </div>
        </div>

        {/* horizontal 100% grey divider */}
        <div className="mb-5 flex w-full border-t border-neutral-200"></div>

        {/* Used Time */}
        <div className="Frame48098809 my-2 flex w-full max-w-72 items-center justify-between self-center px-8 sm:w-8/12 sm:max-w-sm">
          <div className="UsedTime font-['Open Sans'] text-base font-normal leading-snug text-zinc-800">
            <FormattedMessage id="usedTime" />
          </div>
          <div className="Frame48098812 flex items-center justify-start gap-2">
            <div className="24 font-['Open Sans'] text-right text-3xl font-bold leading-10 text-zinc-800">
              {/* 00:00 format */}
              {exerciseResult
                ? new Date(exerciseResult.elapsedTimeSec * 1000)
                  .toISOString()
                  .substring(14, 19)
                : "-"}
            </div>
          </div>
        </div>

        {/* Target Rep Count */}
        <div className="Frame48098809 my-2 flex w-full max-w-72 flex-col items-start justify-start self-center rounded px-8 sm:w-8/12 sm:max-w-sm">
          <div className="Frame48098809 inline-flex items-center justify-between self-stretch">
            <div className="TargetRepCount font-['Open Sans'] text-base font-normal leading-snug text-zinc-800">
              <FormattedMessage id="targetRepCount" />
            </div>
            <div className="Frame48098811 flex items-center justify-start gap-1">
              <div className="font-['Open Sans'] text-right text-2xl font-bold leading-loose text-zinc-800">
                {exerciseResult && `${exerciseResult.targetCount}`}
              </div>
            </div>
          </div>
        </div>

        {/* Player Name Input */}
        <div className="my-2 flex h-16 w-full max-w-72 flex-col items-start justify-start self-center rounded px-8 sm:w-8/12 sm:max-w-sm">
          <TextField
            label={intl.formatMessage({ id: "playerName" })}
            variant="filled"
            fullWidth
            onChange={onPlayerNameChange}
          />
        </div>

        {/* gofa logo here */}
        <img
          src="/gofa-logo.svg"
          alt="gofa logo"
          className="mx-auto mb-6 mt-6 flex w-16"
        />
      </div>

      <div
        className="mt-5 flex h-10 w-1/4 flex-col items-center justify-center rounded-full bg-primary text-center text-sm font-semibold text-white shadow-[0px_0px_15px_0px_rgba(239,114,75,0.70)] hover:cursor-pointer hover:shadow-[0px_0px_30px_0px_rgba(239,114,75,0.70)]"
        onClick={addExerciseResultAndNavigate}
      >
        <FormattedMessage id="doneButton" />
      </div>
    </BlankLayout>
  );
};
