import React, { PropsWithChildren } from "react";

type OverlayProps = {
  style?: React.CSSProperties;
};

export const Overlay: React.FC<PropsWithChildren<OverlayProps>> = ({
  style,
  children,
}) => {
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-inactive"
      style={style}
    >
      {children}
    </div>
  );
};
