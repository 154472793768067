import * as math from "mathjs";
import { PoseLandmarkMap, PoseLandmarkType } from "../models";
import { poseLandmarkToVector } from "./utils";

/**
 * Converts the pose landmarks in the `poseWorldLandmarkMap` to relative vectors.
 *
 * @param {PoseLandmarkMap} poseWorldLandmarkMap - The map of pose landmarks in the world coordinate system.
 * @return {Map<PoseLandmarkType, math.Matrix>} - The map of pose landmark types to their corresponding relative vectors.
 */
export function convertPoseLandmarksToRelativeVectors(
  poseWorldLandmarkMap: PoseLandmarkMap,
): Map<PoseLandmarkType, math.Matrix> {
  const relativeVectorMap = new Map<PoseLandmarkType, math.Matrix>();
  poseWorldLandmarkMap.forEach((poseLandmark, poseLandmarkType) => {
    const vector = poseLandmarkToVector(poseLandmark);
    let relativeVector;
    switch (poseLandmarkType) {
      case PoseLandmarkType.LeftShoulder: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftHip)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightShoulder: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightHip)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.LeftElbow: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftShoulder)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightElbow: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightShoulder)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.LeftWrist: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftElbow)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightWrist: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightElbow)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.LeftKnee: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftAnkle)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightKnee: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightAnkle)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.LeftAnkle: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftKnee)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightAnkle: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightKnee)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.LeftFootIndex: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.LeftAnkle)!,
          ),
        );
        break;
      }
      case PoseLandmarkType.RightFootIndex: {
        relativeVector = math.subtract(
          vector,
          poseLandmarkToVector(
            poseWorldLandmarkMap.get(PoseLandmarkType.RightAnkle)!,
          ),
        );
        break;
      }
      default: {
        relativeVector = vector;
        break;
      }
    }

    relativeVectorMap.set(poseLandmarkType, relativeVector);
  });

  return relativeVectorMap;
}
