import { IntlProvider } from "react-intl";
import { ReactNode } from "react";

import messages_en from "./i18n/en.json";
import messages_zh_Hant from "./i18n/zh-Hant.json";
import messages_zh_Hans from "./i18n/zh-Hans.json";
export const messageSources: {
  [key: string]: { [key: string]: string };
} = {
  en: messages_en,
  "zh-Hant": messages_zh_Hant,
  "zh-Hans": messages_zh_Hans,
};

export const IntlManager = ({
  children,
  locale,
}: {
  children: ReactNode;
  locale: string;
}) => {
  return (
    <IntlProvider
      locale={locale}
      messages={messageSources[locale]}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
};
