import { useEffect, useRef, useState } from "react";

import {
  WebcamView,
} from "../../components";

import {
  useVideoPoseDetector,
  usePoseFrameLandmarkMapPainterRef,
  PoseEMASmoother,
  Pose,
} from "../../packages/gofa-mediapipe-pose-ts";

import { Helmet } from "react-helmet";
import { BehaviorSubject, bufferCount, bufferTime, distinctUntilChanged, map, shareReplay } from 'rxjs';
import { PoseCanvas } from '../../components/PoseCanvas';
import { SitWellPoseFrameLandmarkMapPainter } from '../../packages/gofa-mediapipe-pose-ts/painting-utils/sit-well-pose-frame-landmark-map-painter';
import TopAppBar from './TopAppBar';

import './SitWellPage.css';

export function SitWellPage(): JSX.Element {

  const webcamVideoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [loading, setLoading] = useState(true);
  const [isSittingWell, setIsSittingWell] = useState(null as boolean | null);
  const [shoulderTilt, setShoulderTilt] = useState(null as "left" | "right" | "normal" | null);
  const [headTilt, setHeadTilt] = useState(null as "left" | "right" | "normal" | null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const showSkeletonRef = useRef(showSkeleton);


  // currentPose$, a BehaviorSubject that emits the current pose
  const currentPose$ = new BehaviorSubject<Pose | undefined>(undefined);

  // sampledPose$, pipe the currentPose$ to sample every 1000ms
  const sampledPose$ = currentPose$.pipe(bufferTime(200), map((poses) => poses[poses.length - 1]), distinctUntilChanged(), shareReplay(1),);

  // isSittingWell$, a BehaviorSubject that emits the current sit well status
  const isSittingWell$ = new BehaviorSubject<boolean | null>(null);

  // isSittingBadlyForAWhile$, pipe the isSittingWell$ to check if the user is sitting badly for a while
  // by checking the last 5 seconds of the isSittingWell$ stream, if most of the time the user is sitting badly, then emit true
  const isSittingBadlyForAWhile$ = isSittingWell$.pipe(
    bufferCount(20),
    map((isSittingWellArray) => {

      const isSittingBadlyCount = isSittingWellArray.filter((isSittingWell) => isSittingWell === false).length;

      // check if most of the time the user is sitting badly, 70% of the time
      return (isSittingBadlyCount / isSittingWellArray.length) > 0.7;
    }),
    distinctUntilChanged(),
    shareReplay(1),

  );

  // sample Pose type,
  // const sampledPose$ = currentPose$.pipe(sampleTime(1000), shareReplay(1));

  /*
  export class Pose {
  frameLandmarkMap: PoseLandmarkMap;
  worldLandmarkMap: PoseLandmarkMap;
  ...
  }
  export type PoseLandmarkMap = Map<PoseLandmarkType, PoseLandmark>;
  export enum PoseLandmarkType {
    Nose = 0,
    LeftEyeInner = 1,
    LeftEye = 2,
    LeftEyeOuter = 3,
    RightEyeInner = 4,
    RightEye = 5,
    RightEyeOuter = 6,
    LeftEar = 7,
    RightEar = 8,
    MouthLeft = 9,
    MouthRight = 10,
    LeftShoulder = 11,
    RightShoulder = 12,
    LeftElbow = 13,
    RightElbow = 14,
    LeftWrist = 15,
    RightWrist = 16,
    LeftPinky = 17,
    RightPinky = 18,
    LeftIndex = 19,
    RightIndex = 20,
    LeftThumb = 21,
    RightThumb = 22,
    LeftHip = 23,
    RightHip = 24,
    LeftKnee = 25,
    RightKnee = 26,
    LeftAnkle = 27,
    RightAnkle = 28,
    LeftHeel = 29,
    RightHeel = 30,
    LeftFootIndex = 31,
    RightFootIndex = 32,
  }

  */

  const videoPoseDetector = useVideoPoseDetector(webcamVideoRef, {
    onPoseDetected: (pose) => {

      // Smooth the pose
      const smoothedPose = poseSmoother.smooth(pose);

      currentPose$.next(smoothedPose);
    },
  });

  // Pose smoother
  const smoothingFactor = 0.7;
  const poseSmoother = new PoseEMASmoother(smoothingFactor);

  // Pose frame landmark map painter
  const poseFrameLandmarkMapPainterRef = usePoseFrameLandmarkMapPainterRef(
    canvasRef,
    SitWellPoseFrameLandmarkMapPainter,
  );

  // Initialize and start the video pose detector
  useEffect(() => {
    videoPoseDetector?.initialize().then(() => {
      setLoading(false);

      videoPoseDetector?.start();
    });
  }, [videoPoseDetector]);



  // Initialize when page loaded
  useEffect(() => {

    try {

      // check if browser supports Notification API
      if (!("Notification" in window)) {
        console.error("This browser does not support desktop notification");
      }
      else {

        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted");
          }
          else {
            console.log("Notification permission not granted");

            // alert the user to grant the notification permission
            alert("Please grant the notification permission to receive the sit well alert.");
          }
        });
      }

    }
    catch (error) {
      console.error(`Notification.requestPermission error: ${error}`);
    }


    // listen to pose$
    const subscription = sampledPose$
      .subscribe((pose) => {

        // if the pose is not detected, then we cannot determine if the user is sitting well
        if (!pose) {
          setIsSittingWell(null);
          setShoulderTilt(null);
          setHeadTilt(null);
          return;
        }

        // log the pose
        // console.log(`pose nose: ${pose.frameLandmarkMap.get(0)?.x}, ${pose.frameLandmarkMap.get(0)?.y}`);
        // console.log(`pose left shoulder: ${pose.frameLandmarkMap.get(11)?.x}, ${pose.frameLandmarkMap.get(11)?.y}`);
        // console.log(`pose right shoulder: ${pose.frameLandmarkMap.get(12)?.x}, ${pose.frameLandmarkMap.get(12)?.y}`);

        if (showSkeletonRef.current) {

          // Get the aspect ratio of the video frame
          const frameAspectRatio =
            webcamVideoRef.current!.videoWidth /
            webcamVideoRef.current!.videoHeight;

          // Get the painter
          const poseFrameLandmarkMapPainter =
            poseFrameLandmarkMapPainterRef.current;

          // Paint on the canvas
          poseFrameLandmarkMapPainter?.paint(pose.frameLandmarkMap, {
            frameAspectRatio: frameAspectRatio,
          });

        }

        // check if the pose is sitting well by only checking the head, shoulders, not any other body parts
        const leftEye = pose.frameLandmarkMap.get(2);
        const rightEye = pose.frameLandmarkMap.get(5);
        const centerOfEyes = { x: (leftEye!.x + rightEye!.x) / 2, y: (leftEye!.y + rightEye!.y) / 2 };
        const leftMouth = pose.frameLandmarkMap.get(9);
        const rightMouth = pose.frameLandmarkMap.get(10);
        const centerOfMouth = { x: (leftMouth!.x + rightMouth!.x) / 2, y: (leftMouth!.y + rightMouth!.y) / 2 };

        const leftShoulder = pose.frameLandmarkMap.get(11);
        const rightShoulder = pose.frameLandmarkMap.get(12);

        // check if the user is sitting well,
        // y is the vertical axis, the higher the value, the lower the position
        // x is the horizontal axis, the higher the value, the more to the left

        // measure the angle between the shoulders
        const shoulderAngle = Math.atan2(rightShoulder!.y - leftShoulder!.y, rightShoulder!.x - leftShoulder!.x) * 180 / Math.PI;
        const shoulderAngleAbs = Math.abs(shoulderAngle);

        // log shoulderAngle
        // console.log(`shoulder angle: ${shoulderAngle}`);

        // set the shoulder tilt
        let shoulderTilt: "left" | "right" | "normal" = "normal";
        const shoulderTiltThreshold = 13;
        if (shoulderAngleAbs < 180 - shoulderTiltThreshold) {
          if (shoulderAngle < 0) {
            shoulderTilt = "left";
          }
          else {
            shoulderTilt = "right";
          }
        } else {
          shoulderTilt = "normal";
        }
        setShoulderTilt(shoulderTilt);

        // measure the vertical angle between the eyes center and mouth center
        const headAngle = Math.abs(Math.atan2(centerOfMouth.y - centerOfEyes.y, centerOfMouth.x - centerOfEyes.x) * 180 / Math.PI);

        // log headAngle
        // console.log(`head angle: ${headAngle}`);

        // set the head tilt. 90 is straight up, lower is tilt right, higher is tilt left
        let headTilt: "left" | "right" | "normal" = "normal";
        const headTiltThreshold = 7;
        if (headAngle < 90 - headTiltThreshold) {
          headTilt = "right";
        } else if (headAngle > 90 + headTiltThreshold) {
          headTilt = "left";
        } else {
          headTilt = "normal";
        }
        setHeadTilt(headTilt);

        // if the head tilt is normal and the shoulder tilt is normal, then the user is sitting well
        if (shoulderTilt === "normal" && headTilt === "normal") {
          setIsSittingWell(true);
          isSittingWell$.next(true);
        } else {
          setIsSittingWell(false);
          isSittingWell$.next(false);
        }

      });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // listen to isSittingBadlyForAWhile$
  useEffect(() => {
    const subscription = isSittingBadlyForAWhile$.subscribe((isSittingBadlyForAWhile) => {
      console.log(`isSittingBadlyForAWhile: ${isSittingBadlyForAWhile}`);

      // if the user is sitting badly for a while, then alert the user
      if (isSittingBadlyForAWhile) {

        // check if browser supports Notification API
        if (("Notification" in window)) {
          // use Notification API to alert the user, requestPermission first
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              const notification = new Notification("Sit Well", {
                body: "You are not sitting well, please adjust your sitting posture.",
              });
              notification.onclick = () => {
                window.focus();
                notification.close();
              }

            }
          });
        }

      }

    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    showSkeletonRef.current = showSkeleton
  }, [showSkeleton])

  return (
    <>
      <Helmet>
        <title>Sit Well</title>
        <meta name="description" content="A page to check if you are sitting well" />
      </Helmet>
      {/* loading spinner */}
      {loading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="border-gofa-red h-32 w-32 animate-spin rounded-full border-b-2 border-t-2"></div>
        </div>
      )}
      {/* Show the sit well status at top right corner */}
      <div className="absolute right-0 top-24 m-1 p-1 flex gap-3 flex-col items-end">
        <div className="rounded text-white z-50 text-lg grow-0">
          {/* {isSittingWell === null ? "Detecting..." : isSittingWell ? "Sitting Well ✅" : "NOT Sitting Well ❌"} */}
          {isSittingWell === null ? "Detecting..." : isSittingWell ? <img className="sit-well-indicator" src="bupa/sitting-well.svg" alt="Sitting Well" /> : <img className="sit-well-indicator" src="bupa/sitting-well-not.svg" alt="Sitting Badly" />}
        </div>
        {/* Show the shoulder tilt left or right or normal */}
        {shoulderTilt && (<div className="rounded text-white z-50 text-xs grow-0">
          {/* {shoulderTilt === null ? "" : `Shoulder Tilt: ${shoulderTilt === "normal" ? "Normal ✅" : shoulderTilt === "left" ? "Left ❌" : "Right ❌"}`} */}
          {shoulderTilt === "normal" ? <img className="sit-well-indicator" src="bupa/shoulder-straight.svg" alt="Shoulder Straight" /> : <img className="sit-well-indicator" src="bupa/shoulder-tilt.svg " alt="Shoulder Tilt" />}
        </div>)}
        {/* Show the header tilt left or right or normal */}
        {headTilt && (<div className="rounded text-white z-50 text-xs grow-0">
          {/* {headTilt === null ? "" : `Head Tilt: ${headTilt === "normal" ? "Normal ✅" : headTilt === "left" ? "Left ❌" : "Right ❌"}`} */}
          {headTilt === "normal" ? <img className="sit-well-indicator" src="bupa/head-straight.svg" alt="Head Straight" /> : <img className="sit-well-indicator" src="bupa/head-tilt.svg" alt="Head Tilt" />}
        </div>)}
      </div>


      {/* black background */}
      <div className="flex h-screen w-full flex-col items-center justify-start bg-black">
        <TopAppBar className="w-full"
        />
        <div className="relative flex h-full grow ">
          <div className="flex flex-col flex-grow w-auto h-full justify-center">
            <WebcamView videoRef={webcamVideoRef} styles={{
              width: "auto",
              display: "flex",
              height: "calc(100vh - 100px)",
            }} />
          </div>
          <PoseCanvas canvasRef={canvasRef} />
          {/* <div className="absolute bottom-0 left-0 m-1 flex w-1/3 flex-col gap-2">
            <label className="flex items-center text-white px-3">
              <input
                type="checkbox"
                className="mr-2"
                checked={showSkeleton}
                onChange={(e) => {
                  console.log(`set showSkeleton: ${e.target.checked}`);

                  setShowSkeleton(e.target.checked);
                }}
              />
              Show Skeleton
            </label>
            <div className="flex flex-row gap-2 p-2">
              <button
                className="flex-grow rounded bg-panel p-1 text-white"
                onClick={() => videoPoseDetector?.start()}
              >
                Start
              </button>
              <button
                className="flex-grow rounded bg-panel p-1 text-white"
                onClick={() => videoPoseDetector?.stop()}
              >
                Stop
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
