import { PoseLandmarkerResult } from "@mediapipe/tasks-vision";
import { PoseLandmarkMap } from "./pose-landmark-map";
import { PoseLandmark } from "./pose-landmark";

export class Pose {
  frameLandmarkMap: PoseLandmarkMap;
  worldLandmarkMap: PoseLandmarkMap;

  constructor({
    frameLandmarkMap,
    worldLandmarkMap,
  }: {
    frameLandmarkMap: PoseLandmarkMap;
    worldLandmarkMap: PoseLandmarkMap;
  }) {
    this.frameLandmarkMap = frameLandmarkMap;
    this.worldLandmarkMap = worldLandmarkMap;
  }

  /**
   * Creates a Pose instance from a PoseLandmarkerResult object.
   *
   * @param {PoseLandmarkerResult} poseLandmarkerResult - The result object containing the pose landmarks.
   * @return {Pose | null} The created Pose instance or null if the result constains empty landmarks.
   */
  static fromPoseLandmarkerResult(
    poseLandmarkerResult: PoseLandmarkerResult,
  ): Pose | null {
    // Set the frame landmarks

    // Unpack poseLandmarkerResult
    const rawMultiPersonPoseFrameLandmarks = poseLandmarkerResult.landmarks;
    if (rawMultiPersonPoseFrameLandmarks.length === 0) return null;
    const rawPoseFrameLandmarks = rawMultiPersonPoseFrameLandmarks[0];

    // Create poseFrameLandmarkMap instance
    const poseFrameLandmarkMap: PoseLandmarkMap = new Map();
    rawPoseFrameLandmarks.forEach((rawPoseFrameLandmark, index) => {
      poseFrameLandmarkMap.set(
        index,
        new PoseLandmark(
          rawPoseFrameLandmark.x,
          rawPoseFrameLandmark.y,
          rawPoseFrameLandmark.z,
        ),
      );
    });

    // Set the world landmarks

    // Unpack poseLandmarkerResult
    const rawMultiPersonPoseWorldLandmarks =
      poseLandmarkerResult.worldLandmarks;
    if (rawMultiPersonPoseWorldLandmarks.length === 0) return null;
    const rawPoseWorldLandmarks = rawMultiPersonPoseWorldLandmarks[0];

    // Create poseWorldLandmarkMap instance
    const poseWorldLandmarkMap: PoseLandmarkMap = new Map();
    rawPoseWorldLandmarks.forEach((rawPoseWorldLandmark, index) => {
      poseWorldLandmarkMap.set(
        index,
        new PoseLandmark(
          rawPoseWorldLandmark.x,
          rawPoseWorldLandmark.y,
          rawPoseWorldLandmark.z,
        ),
      );
    });

    // Create a Pose instance
    const pose = new Pose({
      frameLandmarkMap: poseFrameLandmarkMap,
      worldLandmarkMap: poseWorldLandmarkMap,
    });

    return pose;
  }
}
