import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getVertexAI, getGenerativeModel, ChatSession, StartChatParams, GenerateContentRequest } from "firebase/vertexai-preview";
import { APP } from '../firebase';
import { Helmet } from 'react-helmet';
import TopRightButtons from '../components/layout/TopRightButtons';

// Initialize the Vertex AI service
const vertexAI = getVertexAI(APP);

// Initialize the generative model with a model that supports your use case
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

const systemMessage = `You are an AiCoach who helps users with their fitness and health.
For setting up a user's fitness plan, ask users the following questions if not already provided:
1. What's your first name?
2. What's your last name?
3. What’s your gender? (Male, Female, Non-binary, Prefer not to say)
4. What's your date of birth? (To calculate your age)
5. How much do you weigh? (Please specify weight unit)
6. What is your height? (Please specify height unit)
   - Feet:
   - Inches:
7. What is your current fitness level? (Beginner, Intermediate, Advanced)
8. How many days per week do you plan to participate in workouts?
9. What are your fitness goals? (e.g., weight loss, muscle gain, overall fitness)

This setup gathers all necessary information before discussing fitness goals.
Only reply briefly to the user's message, keeping the conversation flowing.`;

const AiCoachPage: React.FC = () => {
  const [messages, setMessages] = useState<
    {
      index: number;
      who: "user" | "model";
      text: string;
    }[]
  >([{
    index: 0,
    who: "model",
    text: "Hello! I am your Ai Coach. How can I help you today?",
  }]);
  const [input, setInput] = useState('');
  const [index, setIndex] = useState(1);
  // use hook to store the chat session
  // const chatSession = useRef<ChatSession>();
  // let chatSession: ChatSession | undefined;

  // Function to handle sending a message to the AiCoach
  const sendMessage = useCallback(async () => {

    // log sendMessage
    console.log('sendMessage start');

    if (input.trim() === '') return;

    const newUserInput = input.trim();;

    const userMessageIndex = index;
    setIndex(userMessageIndex + 1);

    let currentMessages = messages;

    // Add the user input to the messages state
    currentMessages = [
      ...currentMessages,
      { index: userMessageIndex, text: `${newUserInput}`, who: "user" },
    ];
    setMessages(currentMessages);

    // scroll to bottom
    const chatbox = document.getElementById('chatbox');
    chatbox?.scrollTo(0, chatbox.scrollHeight);

    // Clear the input field
    setInput('');

    const coachMessage = {
      text: `...`,
    };

    const coachMessageIndex = index + 1;
    setIndex(coachMessageIndex + 1);

    currentMessages = [
      ...currentMessages,
      { index: coachMessageIndex, text: `${coachMessage.text}`, who: "model" },
    ];

    // Add the AiCoach's response to the messages state
    setMessages(currentMessages);

    // set timeout to scroll to bottom
    setTimeout(() => {
      chatbox?.scrollTo(0, chatbox.scrollHeight);
    }, 100);

    // const prompt = [
    //   {
    //     systemMessage:
    //   },
    //   ...messages,
    // ]

    // To generate text output, call generateContent with the text input
    // const result = await chatSession.current!.sendMessage(newUserInput);
    const request: GenerateContentRequest = {
      systemInstruction: {
        role: "system",
        parts: [{ text: systemMessage }],
      },
      contents: [
        ...currentMessages.map((message) => ({ role: message.who, parts: [{ text: message.text }] })),
        { role: "user", parts: [{ text: newUserInput }] },
      ],
    };

    const result = await model.generateContent(request);

    const responseText = result.response.text();

    coachMessage.text = responseText;

    currentMessages = currentMessages.map((message) => {
      if (message.index === coachMessageIndex) {
        return { ...coachMessage, index: coachMessageIndex, who: "model" };
      }
      return message;
    });

    // Add the AiCoach's response to the messages state
    setMessages(currentMessages);

    // set timeout to scroll to bottom
    setTimeout(() => {
      chatbox?.scrollTo(0, chatbox.scrollHeight);

      // log messages
      console.log(`messages: ${JSON.stringify(messages)}`);
    }, 100);

  }, [index, input, messages]);

  // useCallback
  const handleKeyPress = useCallback(async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await sendMessage();
    }
  }, [sendMessage]);

  useEffect(() => {

    // To generate text output, call generateContent with the text input
    // const startChatParams: StartChatParams = new StartChatParams({
    //   systemInstruction: systemMessage,
    // });
    // const startChatParams: StartChatParams = {
    //   systemInstruction: {
    //     role: "system",
    //     parts: [{ text: systemMessage }],
    //   }
    // };
    // setChatSession(model.startChat(startChatParams));
    // chatSession = model.startChat(startChatParams);
    // chatSession.current = model.startChat(startChatParams);

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress]);


  return (
    <>
      <Helmet>
        <title>GOFA Ai Coach</title>
        <meta name="description" content="Chat with your personal Ai Coach" />
      </Helmet>
      <div className='flex flex-col items-center justify-center h-screen w-full gap-5 p-5'>
        {/* show logout button at top right corner */}
        <div className="flex flex-row justify-between w-full">
          {/* gofa logo here */}
          <img
            src="/gofa-logo.jpg"
            alt="gofa logo"
            className="w-16 h-auto flex grow-0 h-auto shrink"
          />
          <TopRightButtons includeLogout={true} />
        </div>


        <div className='flex flex-col grow gap-2 md:m-5 w-full md:w-96 h-full md:h-4/5 rounded-md overflow-y-auto md:border md:border-gray-100 md:p-6 md:pr-5'>

          <div id="chatbox" className='flex flex-col grow gap-2 h-full overflow-y-auto scrollbar-thin scrollbar hover:scrollbar-thumb-[#FF3702] active:scrollbar-thumb-[#FF3702]  scrollbar-thumb-slate-700 scrollbar-track-slate-300 scrollbar-thumb-rounded-full pr-2'>
            {/* use chat bubbles to display messages, meaning the user's messages are on the right and the AiCoach's messages are on the left */}
            {messages.map((message) => (
              <div key={message.index} className={`flex ${message.who === "user" ? 'justify-end pl-3' : 'justify-start pr-3'}`}>
                <div className={`chat-bubble border border-gray-300 rounded-md p-2 `}>
                  {message.text}
                </div>
              </div>
            ))}
          </div>
          <input
            className="input w-full flex border border-gray-300 rounded-md p-2"
            type="text"
            placeholder='Type your message here...'
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default AiCoachPage;