import { useState } from "react";

export function ProgressBar({
  value,
  minValue = 0.0,
  maxValue = 1.0,
  smoothingFactor = 0.7,
  style,
}: {
  value: number;
  minValue?: number;
  maxValue?: number;
  smoothingFactor?: number;
  style?: React.CSSProperties;
}): JSX.Element {
  // Calculate the fraction
  let fraction = (value - minValue) / (maxValue - minValue);

  // Clip the fraction to the range [0, 1]
  fraction = Math.max(Math.min(fraction, 1.0), 0.0);

  // Calculate the percentage
  const percentage = fraction * 100.0;

  // Convert to string
  const percentageString = `${percentage.toFixed(0)}%`;

  return (
    <div
      className="absolute bottom-0 m-1 flex h-4/6 w-5 flex-col items-center justify-end rounded-md bg-panel p-[0.25rem]"
      style={style}
    >
      <div
        // Smoothly animate the progress bar
        className="w-full rounded-md bg-primary bg-opacity-65 transition-all duration-300 ease-out"
        style={{ height: percentageString }}
      ></div>
    </div>
  );
}
