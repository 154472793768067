import React, { RefObject, useEffect } from "react";

/**
 * The canvas for rendering pose landmark maps.
 *
 * @param {RefObject<HTMLCanvasElement>} canvasRef - The reference to the inner canvas element.
 * @returns {JSX.Element} - The pose canvas element.
 */
export function PoseCanvas({
  canvasRef,
}: {
  canvasRef: RefObject<HTMLCanvasElement>;
}): JSX.Element {
  // Update the canvas size to match the parent
  function updateCanvas(): void {
    // Canvas element
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Get parent element
    const parentElement = canvas.parentElement;
    if (!parentElement) return;

    // Update the canvas size to match the parent
    canvas.width = parentElement.offsetWidth;
    canvas.height = parentElement.offsetHeight;

    // Get the canvas context
    const canvasContext = canvas.getContext("2d");
    if (!canvasContext) return;
  }

  useEffect(() => {
    // Update the canvas for the first time since the window size may not be changed
    updateCanvas();

    // Update the canvas when the window is resized
    window.addEventListener("resize", updateCanvas);

    return () => window.removeEventListener("resize", updateCanvas);
  });

  return (
    <canvas
      className="absolute"
      ref={canvasRef}
      style={{
        // Flip the canvas so that it is in selfie mode
        transform: "scaleX(-1)",
        width: "100%",
        height: "100%",
      }}
    />
  );
}
