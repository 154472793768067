const MAX_SECONDS = 59 * 60 + 59;

/**
 *
 * The elapsed time will be displayed in the format of mm:ss, e.g., 01:23.
 *
 * @param {number} elapsedTimeSec - The elapsed time in seconds
 * @returns {JSX.Element} The rendered StopwatchView component
 */
export function StopwatchView({
  elapsedTimeSec,
  style,
}: {
  elapsedTimeSec: number;
  style?: React.CSSProperties;
}): JSX.Element {
  // Check if the elapsed time exceeds the maximum
  if (elapsedTimeSec > MAX_SECONDS) {
    elapsedTimeSec = MAX_SECONDS;
  }

  // Get the minutes and seconds
  const minutes = Math.floor(elapsedTimeSec / 60);
  const seconds = elapsedTimeSec % 60;

  // Format the minutes and seconds
  const mm = minutes.toString().padStart(2, "0");
  const ss = seconds.toString().padStart(2, "0");

  return (
    <div
      className="w-30 absolute flex h-10 flex-row items-center justify-center gap-2 rounded-md bg-panel p-2 text-xl text-white"
      style={style}
    >
      {/* Stopwatch icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        fill="none"
        className="h-full w-auto"
      >
        <path
          d="M22.9477 8.30673C20.9737 6.00779 18.1817 4.56774 15.1648 4.29214V2.11906H16.2243C16.6028 2.11906 16.9526 1.91715 17.142 1.58922C17.3312 1.26127 17.3312 0.857435 17.142 0.529688C16.9529 0.201936 16.6029 0 16.2243 0H11.9862C11.6074 0 11.2579 0.20191 11.0685 0.529843C10.8793 0.857595 10.8793 1.26163 11.0685 1.58937C11.2576 1.91712 11.6074 2.11922 11.9862 2.11922H13.0457V4.2923C10.152 4.55915 7.46189 5.89607 5.50256 8.04186C3.54271 10.1874 2.45443 12.9873 2.4506 15.8932L2.4502 15.8934C2.44878 18.9235 3.62766 21.8356 5.73642 24.0109C7.84538 26.1868 10.7189 27.4558 13.7478 27.5486C16.7765 27.6417 19.7226 26.5514 21.9606 24.5088C24.1988 22.4666 25.5542 19.6326 25.7383 16.6083C25.9228 13.5837 24.9219 10.606 22.9479 8.30693L22.9477 8.30673ZM20.8479 22.6363C19.0594 24.4246 16.634 25.4291 14.1049 25.4291C11.5758 25.4291 9.1504 24.4244 7.36199 22.6363C5.57373 20.8479 4.56899 18.4225 4.56899 15.8934C4.57182 13.365 5.57756 10.9411 7.3652 9.15355C9.15268 7.36586 11.5766 6.36014 14.105 6.35734L14.1048 6.35754C16.634 6.35754 19.0594 7.36223 20.8478 9.15054C22.636 10.939 23.6408 13.3644 23.6408 15.8935C23.6408 18.4224 22.6361 20.848 20.8478 22.6364L20.8479 22.6363Z"
          fill="white"
        />
        <path
          d="M15.1646 15.4548V11.6553C15.1646 11.2766 14.9627 10.927 14.6348 10.7376C14.307 10.5484 13.903 10.5484 13.5753 10.7376C13.2475 10.9267 13.0454 11.2765 13.0454 11.6553V15.8934C13.0454 16.1744 13.1571 16.4437 13.356 16.6424L15.475 18.7615C15.7441 19.0212 16.1305 19.1202 16.4913 19.0212C16.852 18.9221 17.1338 18.6403 17.2329 18.2796C17.3319 17.9186 17.2329 17.5324 16.973 17.2633L15.1646 15.4548Z"
          fill="white"
        />
      </svg>

      {/* Elapsed time */}
      <div className="">{`${mm}:${ss}`}</div>
    </div>
  );
}
