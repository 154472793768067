import React from "react";

type PauseButtonProps = {
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const PauseButton: React.FC<PauseButtonProps> = (props) => {
  return (
    <div
      className="absolute h-10 w-10 rounded-md bg-panel hover:bg-gray-800"
      onClick={props.onClick}
      style={props.style}
    >
      <svg
        className="h-full w-full text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};
