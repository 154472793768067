// src/context/LocaleContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { messageSources } from "../intl-manager";
interface LocaleContextProps {
  locale: string;
  // setLocale: (locale: string) => void;
  setLocaleAndPersist: (locale: string) => void;
}

const LocaleContext = createContext<LocaleContextProps | undefined>(undefined);

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const [locale, setLocale] = useState(navigator.language);

  useEffect(() => {
    // logic to persist or initialize locale, e.g., from localStorage
    const persistedLocale = localStorage.getItem("locale");
    const navigatorLocale = navigator.language;

    // log
    console.log("get persistedLocale", persistedLocale);
    console.log("get navigatorLocale", navigatorLocale);

    // check persistedLocale against messageSources's keys
    if (
      persistedLocale &&
      Object.keys(messageSources).includes(persistedLocale)
    ) {
      setLocaleAndPersist(persistedLocale);
    }
    // check navigatorLocale against messageSources's keys
    else if (
      navigatorLocale &&
      Object.keys(messageSources).includes(navigatorLocale)
    ) {
      setLocaleAndPersist(navigatorLocale);
    } else {
      setLocaleAndPersist("en");
    }
  }, []);

  const setLocaleAndPersist = (locale: string) => {
    // log
    console.log("setLocaleAndPersist", locale);
    setLocale(locale);
    localStorage.setItem("locale", locale);
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocaleAndPersist }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = (): LocaleContextProps => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error("useLocale must be used within a LocaleProvider");
  }
  return context;
};
