import { LocaleKeys, Localizable } from "../models/localizable";

export const toLocalized = ({
  localizable,
  locale,
}: {
  localizable?: Localizable | undefined;
  locale: LocaleKeys;
}) => {
  // if value is undefined, return empty string
  if (!localizable) {
    return "";
  }

  // convert '-' to '_' in locale
  locale = locale.replace("-", "_") as LocaleKeys;

  // check if the locale exist first
  if (localizable[locale]) {
    return localizable[locale];
  } else {
    // check if the locale starts with zh
    if (locale.startsWith("zh")) {
      // fallback to zh
      return localizable.zh;
    }
  }

  // if not, fallback to en
  return localizable.en;
};
