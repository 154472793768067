import React, { Key, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ExerciseResult } from "../models";
import { formatDate } from "../utils/date-utils";

import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { toLocalized } from "../utils/locale-util";
import { LocaleKeys, Localizable } from "../models/localizable";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

const HKPFA_EXERCISE_RESULT_COLLECTION_NAME = "HKPFAExerciseResults";

type ExerciseHistoryTableProps = {
  exerciseResults: ExerciseResult[];
  styles?: React.CSSProperties;
  className?: string;
};

const ExerciseResultTable: React.FC<ExerciseHistoryTableProps> = (props) => {
  const intl = useIntl();

  const formatElapsedTime = (elapsedTimeSec: number): string => {
    // Get the minutes and seconds
    const minutes = Math.floor(elapsedTimeSec / 60);
    const seconds = elapsedTimeSec % 60;

    // Format the minutes and seconds
    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  // Set the key for each exercise result
  for (const exerciseResult of props.exerciseResults) {
    exerciseResult.key = exerciseResult.date!.getTime().toString();
  }

  // Set of exercise titles
  // const exerciseTitles = new Set<string>();
  // for (const exerciseResult of props.exerciseResults) {
  //   exerciseTitles.add(exerciseResult.title);
  // }
  const exerciseTitles = useMemo(() => {
    const titleMap = new Map<string, string>();

    for (const exerciseResult of props.exerciseResults) {
      if (exerciseResult.exerciseTitle) {
        titleMap.set(
          exerciseResult.exerciseId!,
          toLocalized({
            localizable: exerciseResult.exerciseTitle,
            locale: intl.locale as LocaleKeys,
          }) ?? "",
        );
      }
    }

    return titleMap;
  }, [props.exerciseResults, intl.locale]);

  // Table columns
  const columns: TableColumnsType<ExerciseResult> = [
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      render: (date: Date) => {
        return formatDate(date);
      },
      sorter: (a, b) => {
        return a.date!.getTime() - b.date!.getTime();
      },
      // sortOrder: "descend",
      defaultSortOrder: "descend",
    },
    {
      title: intl.formatMessage({ id: "playerName" }),
      dataIndex: "playerName",
      key: "playerName",
    },
    {
      title: intl.formatMessage({ id: "workoutName" }),
      dataIndex: "exerciseTitle",
      key: "exerciseTitle",
      render: (exerciseTitle: Localizable | undefined) => {
        return exerciseTitle
          ? toLocalized({
            localizable: exerciseTitle,
            locale: intl.locale as LocaleKeys,
          })
          : "";
      },
      filters: Array.from(exerciseTitles.keys()).map((exerciseId) => {
        return {
          text: exerciseTitles.get(exerciseId),
          value: exerciseId,
        };
      }),
      onFilter: (value: boolean | Key, record: ExerciseResult) => {
        return record.exerciseId === (value || "");
      },
    },
    {
      title: intl.formatMessage({ id: "usedTime" }),
      dataIndex: "elapsedTimeSec",
      key: "elapsedTimeSec",
      render: (elapsedTimeSec: number) => {
        return formatElapsedTime(elapsedTimeSec);
      },
      sorter: (a, b) => {
        return a.elapsedTimeSec - b.elapsedTimeSec;
      },
    },
    {
      title: intl.formatMessage({ id: "repsDone" }),
      dataIndex: "targetCount",
      key: "targetCount",
    },
  ];

  return (
    <div className={props.className} style={props.styles}>
      <div className="w-full font-bold text-primary">
        <FormattedMessage id="workoutHistory" />
      </div>
      <Table
        columns={columns}
        dataSource={props.exerciseResults}
        scroll={{ y: 400 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

const MaterrialExerciseResultTable: React.FC<ExerciseHistoryTableProps> = (
  props,
) => {
  const intl = useIntl();

  const formatElapsedTime = (elapsedTimeSec: number): string => {
    // Get the minutes and seconds
    const minutes = Math.floor(elapsedTimeSec / 60);
    const seconds = elapsedTimeSec % 60;

    // Format the minutes and seconds
    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  // Set of exercise titles
  const exerciseTitles = new Set<string>();
  for (const exerciseResult of props.exerciseResults) {
    exerciseResult.key = exerciseResult.date!.getTime().toString();
    exerciseTitles.add(exerciseResult.title);
  }

  // Table columns
  const columns: TableColumnsType<ExerciseResult> = [
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      render: (date: Date) => {
        return formatDate(date);
      },
      sorter: (a, b) => {
        return a.date!.getTime() - b.date!.getTime();
      },
    },
    {
      title: intl.formatMessage({ id: "playerName" }),
      dataIndex: "playerName",
      key: "playerName",
    },
    {
      title: intl.formatMessage({ id: "workoutName" }),
      dataIndex: "title",
      key: "title",
      filters: Array.from(exerciseTitles).map((title) => {
        return { text: title, value: title };
      }),
      onFilter: (value: boolean | Key, record: ExerciseResult) => {
        return record.title === value;
      },
    },
    {
      title: intl.formatMessage({ id: "usedTime" }),
      dataIndex: "elapsedTimeSec",
      key: "elapsedTimeSec",
      render: (elapsedTimeSec: number) => {
        return formatElapsedTime(elapsedTimeSec);
      },
      sorter: (a, b) => {
        return a.elapsedTimeSec - b.elapsedTimeSec;
      },
    },
  ];

  const _columns: GridColDef[] = [
    {
      field: "date",
      headerName: intl.formatMessage({ id: "date" }),
      width: 120,
      renderCell: (params) => {
        return formatDate(params.value as Date);
      },
    },
    {
      field: "playerName",
      headerName: intl.formatMessage({ id: "playerName" }),
      width: 120,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "workoutName" }),
      width: 120,
    },
    {
      field: "elapsedTimeSec",
      headerName: intl.formatMessage({ id: "usedTime" }),
      width: 120,
      renderCell: (params) => {
        return formatElapsedTime(params.value as number);
      },
    },
  ];

  const rows = props.exerciseResults.map((exerciseResult, index) => {
    return {
      id: index,
      ...exerciseResult,
    };
  });

  return (
    <div className={props.className} style={props.styles}>
      <DataGrid
        rows={rows}
        columns={_columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
    </div>
  );

  return (
    <div className={props.className} style={props.styles}>
      <div className="w-full font-bold text-primary">
        <FormattedMessage id="workoutHistory" />
      </div>
      <Table
        columns={columns}
        dataSource={props.exerciseResults}
        scroll={{ y: 400 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default ExerciseResultTable;
