import { plainToInstance } from "class-transformer";
import { JsonDeserializable } from "./serde_json";

export class PoseLandmark implements JsonDeserializable {
  /**
   * The x coordinate of the landmark.
   *
   * If this is a frame landmark, x ranges from 0 to 1, which corresponds to the width of the frame.
   */
  x: number;

  /**
   * The y coordinate of the landmark.
   *
   * If this is a frame landmark, y ranges from 0 to 1, which corresponds to the height of the frame.
   */
  y: number;

  /**
   * The z coordinate of the landmark.
   *
   * If this is a frame landmark, z is the depth of the landmark on the image.
   */
  z: number;

  /**
   * Creates a new instance of the class.
   *
   * If it is a frame landmark, then `z` is the depth of the landmark on the image.
   *
   * If it is a world landmark, the `x`, `y` and `z` are the associated 3D world coordinates.
   *
   * @param {number} param.x - The x coordinate.
   * @param {number} param.y - The y coordinate.
   * @param {number} param.z - The z coordinate.
   */
  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  static fromJson(json: object) {
    return plainToInstance(PoseLandmark, json);
  }
}
