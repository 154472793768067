import { RefObject, useEffect, useState } from "react";
import { Pose } from "../models";
import { VideoPoseDetector } from "../pose-detectors";

/**
 * Creates a video pose detector in the state, and then sets it.
 *
 * @param webcamVideoRef - The reference to the webcam video element.
 * @param {onPoseDetected} - Callback function for when a pose is detected.
 * @returns {VideoPoseDetector | undefined} The video pose detector.
 * Returns `undefined` if the video pose detector is not yet set up.
 */
export function useVideoPoseDetector(
  webcamVideoRef: RefObject<HTMLVideoElement>,
  { onPoseDetected }: { onPoseDetected?: (pose: Pose) => void } = {},
): VideoPoseDetector | undefined {
  // The video pose detector
  const [videoPoseDetector, setVideoPoseDetector] =
    useState<VideoPoseDetector>();

  // Set up the video pose detector
  useEffect(() => {
    // Get the video element
    const video = webcamVideoRef.current;
    if (!video) throw new Error("webcamVideoRef is not pointing to any video");

    // When the video is loaded, create the video pose detector
    video.onloadeddata = () => {
      setVideoPoseDetector(
        new VideoPoseDetector(video, {
          onPoseDetected: onPoseDetected,
        }),
      );
    };

    // Clean up
    return () => {
      videoPoseDetector?.stop();
    };
  }, []);

  return videoPoseDetector;
}
