import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { FIREBASE_CONFIG } from "./config";

/**
 * The Firebase app used in this project.
 */
export const APP = initializeApp(FIREBASE_CONFIG);

/**
 * The Firestore database used in this project.
 */
export const FIRESTORE = getFirestore(APP);

/**
 * The Firebase Auth used in this project.
 */
export const AUTH = getAuth(APP);
