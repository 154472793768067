// src/components/Layout.tsx
import React from "react";
import Header from "./MainHeader";
import Footer from "./MainFooter";

interface LayoutProps {
  children: React.ReactNode;
  includeLogout: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, includeLogout }) => {
  // if includeLogout is undefined, set it to true
  if (includeLogout === undefined) {
    includeLogout = true;
  }

  return (
    <div className="flex h-screen min-h-screen flex-col items-center justify-center bg-gray-100">
      <div className="flex w-full grow flex-col gap-6 overflow-y-auto rounded-lg bg-white p-4 pb-5 pt-6 shadow-md sm:h-[92%] sm:max-h-[92%] sm:p-8 md:w-11/12 lg:w-10/12">
        <Header includeLogout={includeLogout} />
        <div className="flex w-full grow flex-col gap-3 overflow-y-auto sm:gap-5">
          {children}
        </div>
        <Footer className="flex h-auto justify-center pt-2 text-center text-xs text-gray-500 sm:hidden" />
      </div>
      <Footer className="hidden h-3 pt-2 text-center text-xs text-gray-500 sm:flex" />
    </div>
  );
};

export default Layout;
