// src/components/LocaleSwitcher.tsx
import React, { useEffect } from "react";
import { useLocale } from "../context/LocaleContext";

const LocaleSwitcher: React.FC = () => {
  const { locale, setLocaleAndPersist } = useLocale();

  // const handleLocaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setLocale(event.target.value);
  // };

  // watch locale change
  useEffect(() => {
    console.log("locale changed", locale);
  }, [locale]);

  return (
    // <select onChange={handleLocaleChange}>
    //   <option value="en-US">English</option>
    //   <option value="es-ES">Español</option>
    // </select>

    <div className="Frame1321317110 inline-flex h-5 w-32 items-center justify-center gap-2">
      <img src="/svg/ion_earth-sharp.svg" className="h-4 w-4" alt="i18n" />
      <div
        className={`Eng italic text-stone-300 ${locale === "en" ? "font-semibold text-zinc-800" : ""} font-['Open Sans'] cursor-pointer text-base`}
        onClick={() => setLocaleAndPersist("en")}
      >
        ENG
      </div>
      <div className="font-['Open Sans'] text-xs font-normal text-stone-300">
        /
      </div>
      <div
        className={`text-stone-300 ${locale === "zh-Hant" ? "font-semibold text-zinc-800" : ""} font-['Open Sans'] cursor-pointer text-base`}
        onClick={() => setLocaleAndPersist("zh-Hant")}
      >
        繁
      </div>
      <div className="font-['Open Sans'] text-xs font-normal text-stone-300">
        /
      </div>
      <div
        className={`text-stone-300 ${locale === "zh-Hans" ? "font-semibold text-zinc-800" : ""} font-['Open Sans'] cursor-pointer text-base`}
        onClick={() => setLocaleAndPersist("zh-Hans")}
      >
        簡
      </div>
    </div>
  );
};

export default LocaleSwitcher;
