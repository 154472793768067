import { PoseLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";
import { Pose } from "../models";

export class VideoPoseDetector {
  private videoElement: HTMLVideoElement;
  private onPoseDetected?: (pose: Pose) => void;
  private poseLandmarker?: PoseLandmarker;
  private shouldDetectPose = false;
  private lastVideoTime = -1;

  /**
   * Constructor for initializing the object.
   *
   * @param {HTMLVideoElement} videoElement - The HTML video element to work with.
   * @param {Function} options.onPoseDetected - Callback function for when a pose is detected.
   */
  constructor(
    videoElement: HTMLVideoElement,
    {
      onPoseDetected,
    }: {
      onPoseDetected?: (pose: Pose) => void;
    },
  ) {
    this.videoElement = videoElement;
    this.onPoseDetected = onPoseDetected;
  }

  /**
   * Initializes the detector by creating a Mideapipe `poseLandmarker`.
   *
   * @return {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async initialize(): Promise<void> {
    // Create a poseLandmarker
    const poseLandmarker = await this.createPoseLandmarker();

    // Set the poseLandmarker
    this.poseLandmarker = poseLandmarker;
  }

  /**
   * Start pose detection by setting `shouldDetectPose` to true, and call the private method `detect`, which is called repeatedly using `requestAnimationFrame`.
   *
   * @return {void}
   */
  start(): void {
    // Start pose detection by setting `shouldDetectPose` to true
    this.shouldDetectPose = true;

    // Call `detect` to start detecting
    this.detect();
  }

  /**
   * Stop pose detection by setting `shouldDetectPose` to false
   *
   * @return {void}
   */
  stop(): void {
    // Stop pose detection by setting `shouldDetectPose` to false
    this.shouldDetectPose = false;
  }

  private detect() {
    // Don't detect pose if it is not required
    if (!this.shouldDetectPose) return;

    if (!this.poseLandmarker) {
      throw new Error("The video pose detector is not initialized");
    }

    let startTimeMs = performance.now();
    if (true || this.videoElement.currentTime !== this.lastVideoTime) {
      // Update the lastVideoTime
      this.lastVideoTime = this.videoElement.currentTime;

      // Get the result from MediaPipe's poseLandmarker
      const poseLandmarkerResult = this.poseLandmarker.detectForVideo(
        this.videoElement,
        startTimeMs,
      );

      // Convert the result to a Pose instance
      let pose = Pose.fromPoseLandmarkerResult(poseLandmarkerResult);

      // Handle the detected pose
      if (pose) {
        this.onPoseDetected?.(pose);
      }

      requestAnimationFrame(() => {
        this.detect();
      });
    }
  }

  private async createPoseLandmarker(): Promise<PoseLandmarker> {
    // Get vision WasmFileset
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm",
    );

    // Create poseLandmarker
    const poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `/assets/models/pose_landmarker_lite.task`, // changed to local assets because mainland China cannot access Google services
        delegate: "GPU",
      },
      runningMode: "VIDEO",
      numPoses: 1,
    });

    return poseLandmarker;
  }
}
