import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import { FIRESTORE } from "../firebase";
import { Exercise } from "../models";
import { ExerciseView } from "./ExerciseView";

// Collection name in the Firestore database
const EXERCISE_COLLECTION_NAME = "HKPFAExercises";

// The number of shimmer exercise views to show
// It should be a multiple of 2 and 3 since we may have 1, 2 or 3 columns in the grid, and
// we want the placeholder shimmer views to be evenly distributed
const NUM_SHIMMER_EXERCISE_VIEWS = 2 * 3 * 5;

// Total number of reps to complete
const TARGET_COUNT = 15;

export function ExerciseListView(): JSX.Element {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  // Create an array of exercise views
  const exerciseViews = exercises.map((exercise, index) => {
    return <ExerciseView exercise={exercise} key={index} />;
  });

  async function getExercises(): Promise<void> {
    // Query the database
    const snapshot = await getDocs(
      collection(FIRESTORE, EXERCISE_COLLECTION_NAME),
    );

    // Initialise an empty array of exercises
    const exercises: Exercise[] = [];

    snapshot.forEach((doc) => {
      // Get the document data
      const docData = doc.data();

      // log
      console.log(docData);

      // Cast to Exercise
      const exercise = docData as Exercise;

      // Set exercise ID
      exercise.id = doc.id;

      // Set total reps
      exercise.targetCount = TARGET_COUNT;

      // Add to list
      exercises.push(exercise);
    });

    // Set the exercises in state
    setExercises(exercises);

    // Flag as loaded
    setIsLoaded(false);
  }

  // Get the exercises from the database
  useEffect(() => {
    getExercises();
  }, []);

  // Display the exercise views if they are loaded, otherwise display the shimmer views
  return isLoaded ? (
    <ShimmerExerciseListView />
  ) : (
    <div className="mt-5 flex grid w-full grid-cols-1 flex-col content-start justify-start gap-2 overflow-y-auto sm:flex-1 sm:grid-cols-2 lg:grid-cols-2">
      {exerciseViews}
    </div>
  );
}

function ShimmerExerciseView(): JSX.Element {
  return (
    <div className="flex h-24 max-h-32 w-full flex-row justify-between gap-2 overflow-auto px-3 py-2">
      <div className="flex w-full flex-col gap-2">
        <div className="shimmer h-1/2 w-1/2 rounded-md"></div>

        <div className="flex h-1/2 w-full flex-col gap-1">
          <div className="shimmer h-1/2 w-full rounded-md"></div>
          <div className="shimmer h-1/2 w-full rounded-md"></div>
        </div>
      </div>

      <div className="shimmer aspect-square h-full w-auto rounded-md"></div>
    </div>
  );
}

function ShimmerExerciseListView(): JSX.Element {
  // Create an array of shimmer exercise views
  const shimmerExerciseViews = Array(NUM_SHIMMER_EXERCISE_VIEWS)
    .fill(0)
    .map((_, index) => {
      return <ShimmerExerciseView key={index} />;
    });

  return (
    <div className="grid h-auto w-full grid-cols-1 justify-start gap-2 overflow-y-auto p-2 sm:grid-cols-2 lg:grid-cols-3">
      {shimmerExerciseViews}
    </div>
  );
}
