// src/components/Header.tsx
import React from "react";
import TopRightButtons from "./TopRightButtons";

interface MainHeaderProps {
  includeLogout: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = (
  props: MainHeaderProps = { includeLogout: true },
) => {
  // File path to the gofa logo
  const GOFA_LOGO_FILE_PATH = "/gofa-logo.jpg";

  return (
    <header>
      <div className="mb-1 flex flex-row flex-wrap justify-between gap-y-4">
        {/* Logo, align to start, click to navigate to home page */}
        <img
          src={GOFA_LOGO_FILE_PATH}
          className="flex w-20 cursor-pointer"
          alt="gofa logo"
          onClick={() => {
            window.location.href = "/";
          }}
        />
        <TopRightButtons includeLogout={props.includeLogout} />
      </div>
    </header>
  );
};

export default MainHeader;
