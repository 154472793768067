/**
 * Firebase configuration of the GOFA SDK.
 */
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAfCRiduJRCEXfp_ap5i91O0XU-HN1yeAQ",
  authDomain: "gofa-sdk.firebaseapp.com",
  databaseURL:
    "https://gofa-sdk-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gofa-sdk",
  storageBucket: "gofa-sdk.appspot.com",
  messagingSenderId: "1082100926736",
  appId: "1:1082100926736:web:4395cce59b87db2110fd95",
  measurementId: "G-JJBSXXC847",
};
