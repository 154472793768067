const MAX_COUNT = 99;

export function RepCountPanel({
  count,
  targetCount,
  style,
}: {
  count: number;
  targetCount?: number;
  maxCount?: number;
  style?: React.CSSProperties;
}): JSX.Element {
  // Clamp the target count to the maximum
  if (targetCount && targetCount > MAX_COUNT) {
    targetCount = MAX_COUNT;
  }

  // Clamp the current count
  if (targetCount && count > targetCount) {
    count = targetCount;
  } else if (count > MAX_COUNT) {
    count = MAX_COUNT;
  }

  // Format the count and target count
  const countString = count.toString();
  const targetCountString = targetCount
    ? targetCount.toString().padStart(2, "0")
    : undefined;

  return (
    <div
      className="absolute flex h-10 w-auto flex-row items-center justify-center gap-2 rounded-md bg-panel p-2 text-xl text-white"
      style={style}
    >
      {/* Dumbbell icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 28"
        fill="none"
        className="h-full w-auto"
      >
        <g clipPath="url(#clip0_601_18380)">
          <path
            d="M23.9983 17.3363L25.6666 15.668L23.9983 13.9997L19.8333 18.1647L9.83492 8.16634L13.9999 4.00134L12.3316 2.33301L10.6633 4.00134L8.99492 2.33301L6.49825 4.82967L4.82992 3.16134L3.16159 4.82967L4.82992 6.49801L2.33325 8.99467L4.00159 10.663L2.33325 12.3313L4.00159 13.9997L8.16658 9.83467L18.1649 19.833L13.9999 23.998L15.6683 25.6663L17.3366 23.998L19.0049 25.6663L21.5016 23.1697L23.1699 24.838L24.8383 23.1697L23.1699 21.5013L25.6666 19.0047L23.9983 17.3363Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_601_18380">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {/* Count */}
      <div className="">
        <div className="flex flex-row items-end gap-2">
          <p className="text-3xl font-semibold">{countString}</p>
          {targetCount ? <p className="">/{targetCountString}</p> : null}
        </div>
      </div>
    </div>
  );
}
