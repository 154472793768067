import { useEffect, useRef } from "react";

import {
  WebcamView,
  PoseCanvas,
} from "../components";

import {
  useVideoPoseDetector,
  DefaultPoseFrameLandmarkMapPainter,
  usePoseFrameLandmarkMapPainterRef,
  PoseEMASmoother,
} from "../packages/gofa-mediapipe-pose-ts";

import { Helmet } from "react-helmet";


// Import the functions you need from the SDKs you need


export function PoseDetectionPage(): JSX.Element {

  const webcamVideoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const videoPoseDetector = useVideoPoseDetector(webcamVideoRef, {
    onPoseDetected: (pose) => {
      // Smooth the pose
      const smoothedPose = poseSmoother.smooth(pose);

      // Get the aspect ratio of the video frame
      const frameAspectRatio =
        webcamVideoRef.current!.videoWidth /
        webcamVideoRef.current!.videoHeight;

      // Get the painter
      const poseFrameLandmarkMapPainter =
        poseFrameLandmarkMapPainterRef.current;
      if (!poseFrameLandmarkMapPainter) return;

      // Paint on the canvas
      poseFrameLandmarkMapPainter.paint(smoothedPose.frameLandmarkMap, {
        frameAspectRatio: frameAspectRatio,
      });
    },
  });

  // Pose smoother
  const smoothingFactor = 0.7;
  const poseSmoother = new PoseEMASmoother(smoothingFactor);

  // Pose frame landmark map painter
  const poseFrameLandmarkMapPainterRef = usePoseFrameLandmarkMapPainterRef(
    canvasRef,
    DefaultPoseFrameLandmarkMapPainter,
  );

  // Initialize and start the video pose detector
  useEffect(() => {
    videoPoseDetector?.initialize().then(() => {
      videoPoseDetector?.start();
    });
  }, [videoPoseDetector]);


  return (
    <>
      <Helmet>
        <title>Pose Detection Test</title>
        <meta name="description" content="A page for testing pose detection using MediaPipe Pose Estimation" />
      </Helmet>
      <div className="flex h-screen w-full flex-col items-center">


        <div className="relative flex h-full grow ">
          <WebcamView videoRef={webcamVideoRef} />
          <PoseCanvas canvasRef={canvasRef} />

          <div className="absolute bottom-0 left-0 m-1 flex w-1/3 flex-row justify-between gap-1">
            <button
              className="flex-grow rounded bg-panel p-1 text-white"
              onClick={() => videoPoseDetector?.start()}
            >
              Start
            </button>
            <button
              className="flex-grow rounded bg-panel p-1 text-white"
              onClick={() => videoPoseDetector?.stop()}
            >
              Stop
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
